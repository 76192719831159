import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_PAGES } from "../../../constants/modal";
import PagesAddEdit from "../../forms/pages/addEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_PAGES);

      return (
        <Modal
          width={850}
          destroyOnClose
          isOpen={isOpenModal(MODAL_PAGES)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idPage
              ? "Редактировать страницу"
              : "Добавить страницу"
          }
          actions={{
            onOk: () => closeModal(MODAL_PAGES),
            onClose: () => closeModal(MODAL_PAGES)
          }}
          render={() => (
            <PagesAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_PAGES)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
