import React, { useState } from "react";
import { Button, Tooltip } from "antd";

export default ({
  children,
  onClick = () => {},
  type = null,
  tooltipText = [],
  loading = false
}) => {
  const [isVisible, setVisible] = useState(true);

  return (
    <Tooltip
      placement="top"
      title={tooltipText}
      visible={tooltipText.length > 0 && isVisible}
      onVisibleChange={visible => {
        setVisible(visible);
      }}
    >
      <Button
        type={!!type ? type : "primary"}
        size="large"
        loading={loading}
        onClick={e => {
          onClick(e);
          setVisible(true);
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
