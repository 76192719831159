import { combineReducers } from "redux";
import { USER_ACTIONS } from "./types";
import user from "./reducers/user";
import navigation from "./reducers/navigation";
import banners from "./reducers/banners";
import pages from "./reducers/pages";
import news from "./reducers/news";
import catalog from "./reducers/catalog";
import orders from "./reducers/orders";

const Reducer = combineReducers({
  user,
  news,
  pages,
  banners,
  navigation,
  catalog,
  orders
});

const rootReducer = (state = {}, action = {}) => {
  if (action.type === USER_ACTIONS.LOGOUT) {
    state = rootReducer(undefined);
  }

  return Reducer(state, action);
};

export default rootReducer;
