import axios from "axios";
import { loadFromStorage } from "utils/local-storage";
import { API_DOMAIN } from "../constants/api";

const FIVE_DAYS_OLD = 6 * 60 * 60 * 1000;

class Auth {
  _tokens;

  constructor() {
    const store = loadFromStorage();
    const user = store && store.user && store.user.user;

    if (isUserObject(user)) {
      this.startSession(user.tokens);
    }
  }

  get accessToken() {
    return this._tokens ? this._tokens.accessToken : "";
  }

  get expiresAt() {
    return this._tokens
      ? new Date(this._tokens.expiresAt)
      : new Date(Date.now() - FIVE_DAYS_OLD);
  }

  get isAuthenticated() {
    return (
      this._tokens &&
      new Date(new Date().getTime() - FIVE_DAYS_OLD) < this.expiresAt
    );
  }

  getResultData(user) {
    return {
      idUser: user.idUser,
      userEmail: user.userEmail,
      userPhone: user.userPhone,
      userName: user.userName,
      userStatus: user.userStatus,
      idUserGroup: user.idUserGroup,
      tokens: {
        accessToken: user.access_token,
        expiresAt: new Date() // JS timestamp in milliseconds
      },
      loggedIn: true // should be set to true by reducer
    };
  }

  login({ username, password }) {
    return axios({
      method: "post",
      url: `${API_DOMAIN}users/admin-login`,
      data: { username, password, expire_at: new Date() }
    }).then(res => {
      const user = res.data || "";
      if (isErrorResponse(user)) {
        throw user;
      }

      const result = this.getResultData(user);

      this.startSession(result.tokens);
      return result;
    });
  }

  async logout () {
    this.clearSession();
    return Promise.resolve();
  }

  clearSession() {
    this._tokens = undefined;
  }

  startSession(tokens) {
    this._tokens = tokens;
  }
}

function isUserObject(user) {
  return user && user.hasOwnProperty("tokens");
}

function isErrorResponse(res) {
  return res && res.hasOwnProperty("error");
}

const client = new Auth();

export default client;
