import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import "./style.scss";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/news";
import { AnyObject } from "../../../types";
import { NewsSelector } from "../../../redux/selectors";
import { News } from "../../../models/news";
import { addNews, updateNews, setPhoto } from "../../../redux/actions/news";
//@ts-ignore
import CKEditor from 'ckeditor4-react-advanced';
import Photo from "../../inputs/photo";
import { API_DOMAIN } from "../../../constants/api";
import { defined } from "../../../utils/define";
import message from "components/message";
import {CATALOG_PHOTO, CATALOG_SMALL_PHOTO, DATE_FORMAT, NEWS_PHOTO} from "../../../constants/main";
import moment from "moment";
import { Checkbox, DatePicker } from "antd";

interface Props extends FormProps {
  model: News;
  updateNews: (data: News) => void;
  addNews: (data: News) => void;
  setPhoto: Function;
  message: AnyObject;
  news: News[];
}

interface State extends FormState {}

class NewsAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {
      newsActive: 1,
      newsText: "",
      newsDate: moment().unix()
    } as News,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idNews) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idNews
        ? await this.props.updateNews(this.state.model)
        : await this.props.addNews(this.state.model)
    );
  };

  handleLoading = (status: boolean) => {
    this.setState({ loading: status });
  };

  handleLoadImage = (name: string) => {
    if (!!this.props.message.success && !!this.props.message.filePath) {
      this.handleOnChangeInput(name, `${this.props.message.filePath}`);
    } else {
      if (defined(this.props.error)) {
        message.error(
          defined(this.props.error.message)
            ? this.props.error.message
            : "Ошибка на сервере. Попробуйте позже"
        );
      } else {
        message.error("Ошибка на сервере. Попробуйте позже");
      }
    }
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-news">
        <div className="block">
          <div className="column">
            <div>
              <label>
                {fields.newsPreviewImage.title}:
                {this.getTooltipError("newsPreviewImage")}
              </label>
              <Photo
                setPhoto={this.props.setPhoto}
                filePath={"news"}
                onChange={() => this.handleLoadImage("newsPreviewImage")}
                loading={(status: boolean) => this.handleLoading(status)}
                imageUrl={`${API_DOMAIN}${NEWS_PHOTO}${this.state.model.newsPreviewImage}`}
                width={400}
                height={400}
              />
            </div>

            <div>
              <label>
                {fields.newsImage.title}:{this.getTooltipError("newsImage")}
              </label>
              <Photo
                setPhoto={this.props.setPhoto}
                filePath={"news"}
                onChange={() => this.handleLoadImage("newsImage")}
                loading={(status: boolean) => this.handleLoading(status)}
                imageUrl={`${API_DOMAIN}${NEWS_PHOTO}${this.state.model.newsImage}`}
                width={1300}
                height={570}
              />
            </div>

            <div style={{ width: "470px" }}>
              <div>
                <label>
                  {fields.newsName.title}:
                  <Input {...this.getStandartProps(fields.newsName)} />
                </label>
              </div>

              <div className="column">
                <label>
                  {fields.newsDate.title}:<br />
                  <DatePicker
                    format={DATE_FORMAT}
                    value={moment.unix(this.state.model.newsDate)}
                    placeholder={fields.newsDate.title}
                    onChange={(date, dateString) =>
                      this.handleOnChangeInput("newsDate", dateString)
                    }
                  />
                </label>

                <div className="show-hide">
                  <Checkbox
                    checked={this.state.model.newsActive === 1}
                    onChange={() => {
                      this.handleOnChangeInput("newsActive", 1);
                    }}
                  >
                    Показать новость
                  </Checkbox>
                  <Checkbox
                    checked={this.state.model.newsActive === 0}
                    onChange={() => {
                      this.handleOnChangeInput("newsActive", 0);
                    }}
                  >
                    Скрыть новость
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label>
              {fields.newsText.title}:{this.getTooltipError("newsText")}
            </label>

            <CKEditor
              config={{
                language: "ru",
                allowedContent: true,
                toolbar: [
                  { name: 'document', items: [ 'Source', '-'] },
                  { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                  { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                  '/',
                  { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
                  { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                  { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
                  '/',
                  { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                  { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                  { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                  { name: 'about', items: [ 'About' ] }
                ]
              }}
              data={this.props.model ? this.props.model.newsText : ""}
              onChange={(evt: any) => {
                this.handleOnChangeInput("newsText", evt.editor.getData());
              }}
            />
          </div>
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(NewsSelector, { addNews, updateNews, setPhoto })(
  NewsAddEdit
);
