import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_CATALOG_MATERIALS } from "../../../constants/modal";
import MaterialAddEdit from "../../forms/catalog/materialsAddEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_CATALOG_MATERIALS);

      return (
        <Modal
          width={300}
          destroyOnClose
          isOpen={isOpenModal(MODAL_CATALOG_MATERIALS)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idMaterial
              ? "Редактировать материал"
              : "Добавить материал"
          }
          actions={{
            onOk: () => closeModal(MODAL_CATALOG_MATERIALS),
            onClose: () => closeModal(MODAL_CATALOG_MATERIALS)
          }}
          render={() => (
            <MaterialAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_CATALOG_MATERIALS)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
