import { newsDefault } from "./defaultStates";
import { NEWS_ACTIONS } from "../types";

const news = (state, action = {}) => {
  switch (action.type) {
    case NEWS_ACTIONS.ADD:
      return [action.news, ...state];
    case NEWS_ACTIONS.UPDATE:
      return state.map(item =>
        item.idNews === action.news.idNews ? action.news : item
      );
    case NEWS_ACTIONS.REMOVE:
      return state.filter(item => item.idNews !== action.news.idNews);
    case NEWS_ACTIONS.SET:
      return action.news;
    default:
      return state;
  }
};

const error = (state = {}, action = {}) => {
  switch (action.type) {
    case NEWS_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

const message = (state, action = {}) => {
  switch (action.type) {
    case NEWS_ACTIONS.MESSAGE:
      return action.message;
    default:
      return state;
  }
};

export default (state = newsDefault, action = {}) => {
  return {
    news: news(state.news, action),
    message: message(state.message, action),
    error: error(state.error, action)
  };
};
