import { ordersDefault } from "./defaultStates";
import { ORDERS_ACTIONS } from "../types";

const orders = (state, action = {}) => {
  switch (action.type) {
    case ORDERS_ACTIONS.ADD:
      return [action.orders, ...state];
    case ORDERS_ACTIONS.UPDATE:
      return state.map(item =>
        item.idOrder === action.orders.model.idOrder ? action.orders.model : item
      );
    case ORDERS_ACTIONS.REMOVE:
      return state.filter(item => item.idOrder !== action.orders.idOrder);
    case ORDERS_ACTIONS.SET:
      return action.orders;
    default:
      return state;
  }
};

const error = (state = {}, action = {}) => {
  switch (action.type) {
    case ORDERS_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

const message = (state, action = {}) => {
  switch (action.type) {
    case ORDERS_ACTIONS.MESSAGE:
      return action.message;
    default:
      return state;
  }
};

const counts = (state = 0, action = {}) => {
  switch (action.type) {
    case ORDERS_ACTIONS.SET_COUNTS:
      return action.orders.reduce((acc, item) => {
        return { ...acc, [item.orderStatus]: item.count };
      }, {});
    default:
      return state;
  }
};

export default (state = ordersDefault, action = {}) => {
  return {
    orders: orders(state.orders, action),
    counts: counts(state.counts, action),
    message: message(state.message, action),
    error: error(state.error, action)
  };
};
