import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/catalog/sizes";
import { AnyObject } from "../../../types";
import { ModelFormSelector } from "../../../redux/selectors";
import {
  addSize,
  updateSize,
  getCategories
} from "../../../redux/actions/catalog";
import Sizes from "../../../models/catalog/sizes";
import { Select } from "../../inputs/select";
import Categories from "../../../models/catalog/categories";
import {defined} from "../../../utils/define";

interface Props extends FormProps {
  model: Sizes;
  updateSize: (data: Sizes) => void;
  addSize: (data: Sizes) => void;
  message: AnyObject;
  sizes: Sizes[];
  categories: Categories[];
  getCategories: Function;
}

interface State extends FormState {}

class SizesAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {} as Sizes,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idSize) {
      this.setState({ model: this.props.model });
    }

    this.props.getCategories();
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idSize
        ? await this.props.updateSize(this.state.model)
        : await this.props.addSize(this.state.model)
    );
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-pages">
        <div className="block">
          <label>
            {fields.sizeName.title}:
            <Input {...this.getStandartProps(fields.sizeName)} />
          </label>
        </div>

        <label>
          {fields.idCategory.title}:{this.getTooltipError("idCategory")}
          <Select
            name={"idCategory"}
            options={this.props.categories || []}
            labelGetter={(item: Categories) => item.categoryName}
            valueGetter={(item: Categories) => String(item.idCategory)}
            placeholder={fields.idCategory.title}
            value={
              defined(this.state.model.idCategory)
                ? String(this.state.model.idCategory)
                : undefined
            }
            onChange={(val: number) =>
              this.handleOnChangeInput("idCategory", val)
            }
          />
        </label>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(ModelFormSelector, {
  addSize,
  updateSize,
  getCategories
})(SizesAddEdit);
