import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import {MODAL_CATALOG_SIZES} from "../../../constants/modal";
import SizesAddEdit from "../../forms/catalog/sizesAddEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_CATALOG_SIZES);

      return (
        <Modal
          width={300}
          destroyOnClose
          isOpen={isOpenModal(MODAL_CATALOG_SIZES)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idSize
              ? "Редактировать размер"
              : "Добавить размер"
          }
          actions={{
            onOk: () => closeModal(MODAL_CATALOG_SIZES),
            onClose: () => closeModal(MODAL_CATALOG_SIZES)
          }}
          render={() => (
            <SizesAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_CATALOG_SIZES)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
