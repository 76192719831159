import React from "react";
import { Select as AntSelect } from "antd";
import { ModeOption } from "antd/lib/select";

interface SelectProps {
  name: string;
  placeholder?: string;
  mode?: ModeOption;
  group?: boolean;
  disabled?: boolean;
  onChange?: any;
  value: string | number | [] | undefined;
  options: any[];
  labelGetter?: Function;
  valueGetter?: Function;
}

export const Select = ({
  name,
  onChange,
  value = [],
  options = [],
  labelGetter,
  valueGetter,
  placeholder,
  mode,
  group = false,
  disabled = false,
  ...props
}: SelectProps) => (
  <AntSelect
    {...props}
    mode={mode}
    showArrow={true}
    showSearch={true}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    style={{ width: "100%" }}
    disabled={disabled}
  >
    {options.map((item, id) =>
      group ? (
        <AntSelect.OptGroup key={!!labelGetter ? labelGetter(item) : item}>
          {item && item.children && item.children.map((option: any, idOption: any) => (
            <AntSelect.Option
              key={!!labelGetter ? labelGetter(option) : option}
              value={!!valueGetter ? valueGetter(option) : idOption}
            >
              {!!labelGetter ? labelGetter(option) : option}
            </AntSelect.Option>
          ))}
        </AntSelect.OptGroup>
      ) : (
        <AntSelect.Option
          key={!!labelGetter ? labelGetter(item) : item}
          value={!!valueGetter ? valueGetter(item) : id}
        >
          {!!labelGetter ? labelGetter(item) : item}
        </AntSelect.Option>
      )
    )}
  </AntSelect>
);
