import React from "react";
import InputMask from "react-input-mask";
import { Input as AntdInput, Icon, Tooltip } from "antd";

export const getError = errors => (
  <>
    {!!errors && (
      <Tooltip title={errors}>
        <Icon type="info-circle" style={{ color: "#f5222d" }} />
      </Tooltip>
    )}
  </>
);

export const Input = ({
  prefix = "",
  placeholder = "",
  onChange,
  name,
  error = "",
  ...props
}) => (
  <AntdInput
    {...props}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    prefix={!!prefix ? prefix : getError(error)}
    suffix={!!prefix ? getError(error) : ""}
  />
);

export const EmailInput = ({
  name,
  onChange,
  placeholder,
  error = "",
  ...props
}) => (
  <Input
    {...props}
    error={error}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
  />
);

export const PhoneInput = ({
  name,
  onChange,
  placeholder,
  error = "",
  disabled = false,
  ...props
}) => (
  <InputMask
    mask="+7 (999) 999-99-99"
    value={props.value}
    onChange={onChange}
    disabled={disabled}
  >
    {inputProps => (
      <Input
        {...inputProps}
        {...props}
        error={error}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        prefix={<Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />}
      />
    )}
  </InputMask>
);

export const PasswordInput = ({
  name,
  onChange,
  placeholder,
  error = "",
  ...props
}) => (
  <AntdInput.Password
    {...props}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    prefix={getError(error)}
  />
);
