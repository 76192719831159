import {
  BannersStore,
  PagesStore,
  UserStore,
  CatalogStore,
  NewsStore, OrdersStore
} from "../../types/redux";
import { Users } from "../../models/users";

export const navDefault = {
  current: "",
  prev: "",
  sub: []
};

export const userDefault: UserStore = {
  user: {} as Users,
  error: []
};

export const bannersDefault: BannersStore = {
  banners: [],
  error: [],
  message: {}
};

export const pagesDefault: PagesStore = {
  pages: [],
  error: [],
  message: {}
};

export const newsDefault: NewsStore = {
  news: [],
  error: [],
  message: {}
};

export const ordersDefault: OrdersStore = {
  orders: [],
  error: [],
  message: {}
};

export const catalogDefault: CatalogStore = {
  error: [],
  message: [],
  categories: [],
  materials: [],
  sizes: [],
  insets: [],
  models: []
};
