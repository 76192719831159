import { _getErrors, _isLoggedIn } from "./user";

export const getUserErrors = state => _getErrors(state.user);
export const isLoggedIn = state => _isLoggedIn(state.user.user);
export const isPage = (state, rules) => rules.includes(Number(state.user.user.idUserGroup));

// --- AppView Selector
export const AppViewSelector = state => ({
  user: {
    idUserGroup: state.user.user.idUserGroup,
    isLoggedIn: isLoggedIn(state),
    error: getUserErrors(state)
  },
  menu: {
    currentActiveView: state.navigation.current,
    prevActiveView: state.navigation.prev,
    currentActiveSub: state.navigation.activeSub,
    subNavigation: state.navigation.sub
  }
});

// --- Types Selector
export const ErrorSelector = state => ({
  error: getUserErrors(state)
});

// --- Types Selector
export const BannersSelector = state => {
  return {
    isPage: isPage(state, [1,2]),
    banners: state.banners.banners,
    message: state.banners.message,
    error: state.banners.error
  };
};

// --- Types Selector
export const PagesSelector = state => {
  return {
    isPage: isPage(state, [1]),
    pages: state.pages.pages,
    message: state.pages.message,
    error: state.pages.error
  };
};

// --- Types Selector
export const CatalogSelector = state => {
  return {
    categories: state.catalog.categories,
    materials: state.catalog.materials,
    sizes: state.catalog.sizes,
    insets: state.catalog.insets,
    models: state.catalog.models,
    isPage: isPage(state, [1,3]),
  };
};

// --- Types Selector
export const ModelFormSelector = state => {
  return {
    insets: state.catalog.insets,
    sizes: state.catalog.sizes,
    categories: state.catalog.categories,
    materials: state.catalog.materials,
    message: state.catalog.message,
    error: state.catalog.error
  };
};

// --- Types Selector
export const NewsSelector = state => {
  return {
    news: state.news.news,
    message: state.news.message,
    error: state.news.error,
    isPage: isPage(state, [1,2]),
  };
};

// --- Types Selector
export const OrdersSelector = state => {
  return {
    orders: state.orders.orders,
    counts: state.orders.counts,
    materials: state.catalog.materials,
    insets: state.catalog.insets,
    searchModels: state.catalog.models,
    isPage: isPage(state, [1,2]),
  };
};
