import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT
} from "../constants/api";
import { defined } from "../utils/define";

export const BANNERS = {
  GET: data => ({
    method: METHOD_GET,
    url: `banners`
  }),
  ADD: data => ({
    method: METHOD_POST,
    url: `banners`,
    data
  }),
  UPDATE: data => ({
    method: METHOD_PUT,
    url: `banners/${data.idBanner}`,
    data
  }),
  DELETE: data => ({
    method: METHOD_DELETE,
    url: `banners/${data.idBanner}`
  })
};

export const PAGES = {
  GET: data => ({
    method: METHOD_GET,
    url: `pages`
  }),
  ADD: data => ({
    method: METHOD_POST,
    url: `pages`,
    data
  }),
  UPDATE: data => ({
    method: METHOD_PUT,
    url: `pages/${data.idPage}`,
    data
  }),
  DELETE: data => ({
    method: METHOD_DELETE,
    url: `pages/${data.idPage}`
  })
};

export const NEWS = {
  GET: data => ({
    method: METHOD_GET,
    url: `news?active=1`
  }),
  ADD: data => ({
    method: METHOD_POST,
    url: `news`,
    data
  }),
  UPDATE: data => ({
    method: METHOD_PUT,
    url: `news/${data.idNews}`,
    data
  }),
  DELETE: data => ({
    method: METHOD_DELETE,
    url: `news/${data.idNews}`
  })
};

export const ORDERS = {
  GET: data => ({
    method: METHOD_GET,
    url: `orders${data.status ? `?status=${data.status}` : ""}${
      data.count ? `?count=${data.count}` : ""
    }`
  }),
  ADD: data => ({
    method: METHOD_POST,
    url: `orders`,
    data
  }),
  UPDATE: data => ({
    method: METHOD_PUT,
    url: `orders`,
    data
  })
};

export const CATALOG = {
  GET_MODELS: data => ({
    method: METHOD_POST,
    url: `catalog-models`,
    data
  }),
  GET_SIZES: data => ({
    method: METHOD_GET,
    url: `catalogsizes`
  }),
  GET_INSETS: data => ({
    method: METHOD_GET,
    url: `cataloginsets`
  }),
  GET_MATERIALS: data => ({
    method: METHOD_GET,
    url: `catalogmaterials`
  }),
  GET_CATEGORIES: data => ({
    method: METHOD_GET,
    url: `catalogcategories`
  }),
  ADD_SIZE: data => ({
    method: METHOD_POST,
    url: `catalogsizes`,
    data
  }),
  UPDATE_SIZE: data => ({
    method: METHOD_PUT,
    url: `catalogsizes/${data.idSize}`,
    data
  }),
  DELETE_SIZE: data => ({
    method: METHOD_DELETE,
    url: `catalogsizes/${data.idSize}`
  }),
  ADD_INSET: data => ({
    method: METHOD_POST,
    url: `cataloginsets`,
    data
  }),
  UPDATE_INSET: data => ({
    method: METHOD_PUT,
    url: `cataloginsets/${data.idSize}`,
    data
  }),
  DELETE_INSET: data => ({
    method: METHOD_DELETE,
    url: `cataloginsets/${data.idInset}`
  }),
  UPDATE_INSET_RELATIONS: data => ({
    method: METHOD_POST,
    url: `cataloginsetsrelations`,
    data
  }),
  UPDATE_MATERIAL: data => ({
    method: METHOD_PUT,
    url: `catalogmaterials/${data.idMaterial}`,
    data
  }),
  DELETE_MATERIAL: data => ({
    method: METHOD_DELETE,
    url: `catalogmaterials/${data.idMaterial}`
  }),
  DELETE_MODEL: data => ({
    method: METHOD_DELETE,
    url: `catalog-models/${data.idModel}`
  }),
  UPDATE_MODEL: data => ({
    method: METHOD_PUT,
    url: `catalog-models/${data.idModel}`,
    data
  }),
  ADD_MODEL: data => ({
    method: METHOD_POST,
    url: `catalog-models`,
    data
  }),
  ADD_CATEGORY: data => ({
    method: METHOD_POST,
    url: `catalogcategories`,
    data
  }),
  UPDATE_CATEGORY: data => ({
    method: METHOD_PUT,
    url: `catalogcategories/${data.idCategory}`,
    data
  }),
  DELETE_CATEGORY: data => ({
    method: METHOD_DELETE,
    url: `catalogcategories/${data.idCategory}`
  }),
  UPDATE_PRICES: data => ({
    method: METHOD_POST,
    url: `catalogprices`,
    data
  })
};

export const PHOTOS = {
  SET_PHOTO: data => ({
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    method: METHOD_POST,
    url: `files/upload`,
    data
  })
};
