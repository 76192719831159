import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_BANNERS } from "../../../constants/modal";
import BannersAddEdit from "../../forms/banners/addEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_BANNERS);

      return (
        <Modal
          width={650}
          destroyOnClose
          isOpen={isOpenModal(MODAL_BANNERS)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idBanner
              ? "Редактировать баннер"
              : "Добавить баннер"
          }
          actions={{
            onOk: () => closeModal(MODAL_BANNERS),
            onClose: () => closeModal(MODAL_BANNERS)
          }}
          render={() => (
            <BannersAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_BANNERS)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
