import React from "react";
import { MODAL_ORDERS } from "../../constants/modal";
import { ModalsConsumer } from "../../components/modals/context";
import { Button } from "antd";
import { ORDER_STATUSES } from "../../constants/main";
import { required, validEmail, validPhone } from "../reactive";

export const fields = [
  {
    field: "idUser",
    title: "Клиент",
    rules: [required],
    noShow: true
  },
  {
    field: "userEmail",
    title: "E-mail",
    rules: [required, validEmail]
  },
  {
    field: "userPhone",
    title: "Телефон",
    rules: [required, validPhone]
  },
  {
    field: "orderDelivery",
    title: "Доставка",
    render: text => (Number(text) === 1 ? `Доставка` : "Самовывоз")
  },
  {
    field: "orderDeliveryAddress",
    title: "Адрес",
    rules: [required]
  },
  {
    field: "orderDeliveryDate",
    title: "Дата доставки",
    render: text => text
  },
  {
    field: "orderDeliveryTime",
    title: "Время доставки"
  },
  {
    field: "orderUpdateDate",
    title: "Обновление заказа"
  },
  {
    field: "orderStatus",
    title: "Статус",
    render: (text, record) => (
      <nobr>
        <span className={`status color-${ORDER_STATUSES[text].class}`}>
          {ORDER_STATUSES[text].name}
        </span>
      </nobr>
    )
  },
  {
    field: "orderComment",
    title: "Коммент",
    noShow: true
  },
  {
    field: "orderCount",
    title: "Кол-во",
    render: (text, record) =>
      record.models.reduce(
        (summ, model) => (summ += Number(model.orderCount)),
        0
      )
  },
  {
    field: "orderPayment",
    title: "Оплата",
    render: text => (Number(text) === 1 ? `При получении` : "Онлайн")
  },
  {
    field: "orderPaid",
    title: "Оплачено",
    render: text => new Intl.NumberFormat().format(Number(text)),
    rules: [required]
  },
  {
    field: "orderDeliveryPrice",
    title: "Стоимость доставки",
    rules: [required]
  },
  {
    field: "orderPrice",
    title: "Итого",
    rules: [],
    render: (text, record) => (
      <b>
        <nobr>
          {new Intl.NumberFormat().format(
            Number(text) === 0
              ? record.models.reduce(
                  (summ, model) =>
                    (summ +=
                      Number(model.orderPrice) * Number(model.orderCount)) +
                    Number(record.orderDeliveryPrice),
                  0
                )
              : Number(text)
          )}{" "}
          ₽
        </nobr>
      </b>
    )
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_ORDERS, {
                contextData: record
              });
              openModal(MODAL_ORDERS);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
