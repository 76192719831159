import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_CATALOG_CATEGORY } from "../../../constants/modal";
import CategoriesAddEdit from "../../forms/catalog/categoriesAddEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_CATALOG_CATEGORY);

      return (
        <Modal
          width={300}
          destroyOnClose
          isOpen={isOpenModal(MODAL_CATALOG_CATEGORY)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idCategory
              ? "Редактировать категорию"
              : "Добавить категорию"
          }
          actions={{
            onOk: () => closeModal(MODAL_CATALOG_CATEGORY),
            onClose: () => closeModal(MODAL_CATALOG_CATEGORY)
          }}
          render={() => (
            <CategoriesAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_CATALOG_CATEGORY)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
