import { bannersDefault } from "./defaultStates";
import { BANNERS_ACTIONS } from "../types";

const banners = (state, action = {}) => {
  switch (action.type) {
    case BANNERS_ACTIONS.ADD:
      return [...state, action.banner];
    case BANNERS_ACTIONS.UPDATE:
      return state.map(item =>
        item.idBanner === action.banner.idBanner ? action.banner : item
      );
    case BANNERS_ACTIONS.REMOVE:
      return state.filter(item => item.idBanner !== action.banner.idBanner);
    case BANNERS_ACTIONS.SET:
      return action.banners;
    default:
      return state;
  }
};

const error = (state = {}, action = {}) => {
  switch (action.type) {
    case BANNERS_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

const message = (state, action = {}) => {
  switch (action.type) {
    case BANNERS_ACTIONS.MESSAGE:
      return action.message;
    default:
      return state;
  }
};

export default (state = bannersDefault, action = {}) => {
  return {
    banners: banners(state.banners, action),
    message: message(state.message, action),
    error: error(state.error, action)
  };
};
