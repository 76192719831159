import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
//@ts-ignore
import CKEditor from 'ckeditor4-react-advanced';
import OkButton from "../../buttons/ok";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/catalog/models";
import { AnyObject } from "../../../types";
import { ModelFormSelector } from "../../../redux/selectors";
import {
  updateModel,
  addModel,
  setPhoto,
  getCategories,
  getMaterials,
  getSizes,
  addInset,
  getInsets,
  updatePrices,
  updateInsetRelations
} from "../../../redux/actions/catalog";
import Sizes from "../../../models/catalog/sizes";
import Prices from "../../../models/catalog/prices";
import { Select } from "../../inputs/select";
import Categories from "../../../models/catalog/categories";
import Models from "../../../models/catalog/models";
import Materials from "../../../models/catalog/materials";
import Photo from "../../inputs/photo";
import { API_DOMAIN } from "../../../constants/api";
import { defined } from "../../../utils/define";
import message from "components/message";
import "./style.scss";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons/lib";
import { CATALOG_SMALL_PHOTO } from "../../../constants/main";
import Insets from "../../../models/catalog/insets";

interface Props extends FormProps {
  model: AnyObject;
  categories: Categories[];
  materials: Materials[];
  updateModel: (data: Models) => void;
  addModel: (data: Models) => void;
  message: AnyObject;
  sizes: Sizes[];
  insets: Insets[];

  getCategories: Function;
  getMaterials: Function;
  getSizes: Function;
  getInsets: Function;
  addInset: Function;
  updateInsetRelations: Function;
  updatePrices: Function;
  match: AnyObject;
}

interface State extends FormState {
  prices: AnyObject[];
  price: number;
}

class ModelsAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {} as Sizes,
    loading: false,
    tooltipError: [],
    imageUrl: "",
    prices: []
  };

  async componentDidMount() {
    if (
      (!!this.props.model && !!this.props.model.idModel) ||
      !!this.props.model.idCategory
    ) {
      await this.setState({
        model: {
          ...this.props.model,
          modelPhotoArray: this.props.model.modelPhoto
            ? this.props.model.modelPhoto.split("||")
            : []
        },
        prices: this.props.model.prices.map((item: Prices) => ({
          size: Number(item.idSize),
          price: item.pricePrice
        }))
      });
    }

    await this.props.getCategories();
    await this.props.getMaterials();
    await this.props.getSizes();
    await this.props.getInsets();
  }

  handleSubmit = async () => {
    await this.setState({
      model: {
        ...this.state.model,
        modelPhoto: this.state.model.modelPhotoArray.join("||"),
        modelWeight: String(this.state.model.modelWeight).replace(",", ".")
      }
    });

    this.submitForm(fields, async () => {
      const results = (!!this.props.model && !!this.props.model.idModel
        ? await this.props.updateModel(this.state.model)
        : await this.props.addModel(this.state.model)) as any;

      if (
        defined(results) &&
        defined(results.model) &&
        defined(results.model.idModel)
      ) {
        this.props.updateInsetRelations({
          idModel: results.model.idModel,
          modelInsets: this.state.model.modelInsets
        });

        /*this.props.updatePrices({
          idModel: results.model.idModel,
          prices: this.state.prices
        });*/
      }

      return results;
    });
  };

  handleLoading = (status: boolean) => {
    this.setState({ loading: status });
  };

  handleLoadImage = () => {
    if (!!this.props.message.success && !!this.props.message.filePath) {
      this.state.model.modelPhotoArray.push(`${this.props.message.filePath}`);
      this.handleOnChangeInput(
        "modelPhotoArray",
        this.state.model.modelPhotoArray
      );
    } else {
      if (defined(this.props.error)) {
        message.error(
          defined(this.props.error.message)
            ? this.props.error.message
            : "Ошибка на сервере. Попробуйте позже"
        );
      } else {
        message.error("Ошибка на сервере. Попробуйте позже");
      }
    }
  };

  removePhoto = (idx: number) => {
    this.setState({
      model: {
        ...this.state.model,
        modelPhotoArray: this.state.model.modelPhotoArray.filter(
          (photo: string, id: number) => id !== idx
        )
      }
    });
  };

  handleOnChangePrice = (name: string, idx: number, val: any) => {
    let currentPrice = this.state.prices;

    currentPrice[idx][name] = val;

    this.setState({ prices: currentPrice });
  };

  onAddPrice = () => {
    this.setState({ prices: [...this.state.prices, {}] });
  };

  onRemovePrice = (size: number) => {
    this.setState({
      prices: this.state.prices.filter(price => price.size !== size)
    });
  };

  render() {
    const fields = formFields as AnyObject;

    const idSizesPrices = this.state.prices.map(price => price.size);
    const sizes = this.props.sizes.filter(
      size =>
        defined(this.state.model.idCategory) &&
        Number(this.state.model.idCategory) === Number(size.idCategory)
    );
    const restSizes = sizes.filter(
      size => !idSizesPrices.includes(size.idSize)
    );
    return (
      <div className="app-form add-edit-model">
        <div className="block">
          <div className="column">
            <div>
              <label>
                {fields.modelPhoto.title}:{this.getTooltipError("modelPhoto")}
              </label>

              <Photo
                setPhoto={this.props.setPhoto}
                filePath={"models"}
                onChange={() => this.handleLoadImage()}
                loading={(status: boolean) => this.handleLoading(status)}
                imageUrl={""}
                width={600}
                height={600}
                smallParams={{ width: 200, height: 200 }}
              />
            </div>
            <div style={{ marginTop: "5px" }}>
              <div className="column">
                <label>
                  {fields.modelCode.title}:
                  <Input {...this.getStandartProps(fields.modelCode)} />
                </label>
                <label>
                  {fields.modelName.title}:
                  <Input {...this.getStandartProps(fields.modelName)} />
                </label>
              </div>
              <div className="column">
                <label>
                  {fields.modelWeight.title}:
                  <Input {...this.getStandartProps(fields.modelWeight)} />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="images">
          {this.state.model.modelPhotoArray &&
            this.state.model.modelPhotoArray.map(
              (photo: string, idx: number) => (
                <div className="item" key={photo}>
                  <CloseCircleOutlined onClick={() => this.removePhoto(idx)} />
                  <img
                    src={`${API_DOMAIN}${CATALOG_SMALL_PHOTO}${photo}`}
                    height="80"
                  />
                </div>
              )
            )}
        </div>

        <label>
          {fields.modelInsets.title}:
          <Select
            mode={"multiple"}
            name={"pageParent"}
            options={this.props.insets}
            labelGetter={(item: Insets) => item.insetName}
            valueGetter={(item: Insets) => item.idInset}
            placeholder={fields.modelInsets.title}
            value={this.state.model.modelInsets}
            onChange={(val: number) =>
              this.handleOnChangeInput("modelInsets", val)
            }
          />
        </label>

        <label>
          {fields.idCategory.title}:{this.getTooltipError("idCategory")}
          <Select
            name={"idCategory"}
            options={this.props.categories || []}
            labelGetter={(item: Categories) => item.categoryName}
            valueGetter={(item: Categories) => String(item.idCategory)}
            placeholder={fields.idCategory.title}
            value={
              defined(this.state.model.idCategory)
                ? String(this.state.model.idCategory)
                : undefined
            }
            onChange={(val: number) =>
              this.handleOnChangeInput("idCategory", val)
            }
          />
        </label>
        <label>
          {fields.idMaterial.title}:{this.getTooltipError("idMaterial")}
          <Select
            name={"idMaterial"}
            options={this.props.materials}
            labelGetter={(item: Materials) => item.materialName}
            valueGetter={(item: Materials) => item.idMaterial}
            placeholder={fields.idMaterial.title}
            value={this.state.model.idMaterial}
            onChange={(val: number) =>
              this.handleOnChangeInput("idMaterial", val)
            }
          />
        </label>

        <div>
          <label>
            {fields.modelDescription.title}:
            {this.getTooltipError("modelDescription")}
          </label>

          <CKEditor
            config={{
              language: "ru",
              allowedContent: true,
              toolbar: [
                { name: 'document', items: [ 'Source', '-'] },
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                '/',
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
                { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
                '/',
                { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                { name: 'about', items: [ 'About' ] }
              ]
            }}
            data={this.props.model ? this.props.model.modelDescription : ""}
            onChange={(evt: any) => {
              this.handleOnChangeInput(
                "modelDescription",
                evt.editor.getData()
              );
            }}
          />
        </div>

        {/*<div className={"prices"}>
          <h2>
            Цены{" "}
            {restSizes.length > 0 &&
              this.state.prices.length < sizes.length && (
                <PlusCircleOutlined onClick={this.onAddPrice} />
              )}
          </h2>

          {sizes.length > 0 ? (
            this.state.prices.map((price: AnyObject, idx: number) => {
              const size = sizes.find(item => price.size === item.idSize);
              return (
                <div className={"column"} key={`price-${idx}`}>
                  <Select
                    name={"priceSize"}
                    options={restSizes}
                    labelGetter={(item: Sizes) => item.sizeName}
                    valueGetter={(item: Sizes) => Number(item.idSize)}
                    placeholder={"Размер"}
                    value={defined(size) ? size.sizeName : undefined}
                    onChange={(val: number) =>
                      this.handleOnChangePrice("size", idx, val)
                    }
                  />

                  <Input
                    value={price.price}
                    name={"price"}
                    placeholder="Цена"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleOnChangePrice("price", idx, e.target.value)
                    }
                  />

                  <Input
                    value={price.quantity}
                    name={"quantity"}
                    placeholder="Кол-во"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleOnChangePrice("quantity", idx, e.target.value)
                    }
                  />

                  <CloseCircleOutlined
                    onClick={() => this.onRemovePrice(price.size)}
                  />
                </div>
              );
            })
          ) : (
            <>
              <Input
                value={
                  defined(this.state.prices[0])
                    ? this.state.prices[0].price
                    : undefined
                }
                name={"price"}
                placeholder="Цена"
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  await this.setState({ prices: [{ size: 0, price: 0 }] });
                  await this.handleOnChangePrice("price", 0, value);
                }}
              />
              <Input
                value={defined(this.state.prices[0])
                  ? this.state.prices[0].quantity
                  : undefined}
                name={"quantity"}
                placeholder="Кол-во"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleOnChangePrice("quantity", 0, e.target.value)
                }
              />
            </>
          )}
        </div>*/}

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(ModelFormSelector, {
  setPhoto,
  updateModel,
  addModel,
  getCategories,
  getMaterials,
  addInset,
  getSizes,
  getInsets,
  updatePrices,
  updateInsetRelations
})(ModelsAddEdit);
