import { PAGES_ACTIONS } from "../types";
import { PAGES } from "../../api/endpoints";
import { apiAction } from "./api";

const setPages = pages => ({
  type: PAGES_ACTIONS.SET,
  pages
});

const setPage = page => ({
  type: PAGES_ACTIONS.ADD,
  page
});

const putPage = page => ({
  type: PAGES_ACTIONS.UPDATE,
  page
});

const removePage = page => ({
  type: PAGES_ACTIONS.REMOVE,
  page
});

const errorPages = error => ({
  type: PAGES_ACTIONS.ERROR,
  error
});

export const getPages = () => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PAGES.GET(),
      onSuccess: data => setPages(data),
      onFailure: e => e
    })
  );
};

export const addPage = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PAGES.ADD(data),
      onSuccess: data => setPage(data),
      onFailure: e => errorPages(e.response.data)
    })
  );
};

export const updatePage = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PAGES.UPDATE(data),
      onSuccess: () => putPage(data),
      onFailure: e => errorPages(e.response.data)
    })
  );
};

export const deletePage = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PAGES.DELETE(data),
      onSuccess: () => removePage(data),
      onFailure: e => errorPages(e.response.data)
    })
  );
};
