import { required } from "../reactive";
import ReactHtmlParser from "react-html-parser";
import React from "react";
import { MODAL_NEWS } from "../../constants/modal";
import { ModalsConsumer } from "../../components/modals/context";
import { Modal, Button, Checkbox } from "antd";
import { DATE_FORMAT, NEWS_PHOTO } from "../../constants/main";
import moment from "moment";
import {API_DOMAIN} from "../../constants/api";

export const fields = [
  {
    field: "newsImage",
    title: "Картинка",
    rules: [required],
    noShow: true
  },
  {
    field: "newsPreviewImage",
    title: "Превью",
    rules: [required],
    render: (text, record) => <img src={`${API_DOMAIN}${NEWS_PHOTO}${text}`} height={50} />
  },
  {
    field: "newsName",
    title: "Название",
    rules: [required]
  },
  {
    field: "newsDate",
    title: "Дата",
    rules: [required],
    render: (text, record) => moment.unix(text).format(DATE_FORMAT)
  },
  {
    field: "newsActive",
    title: "Показать/скрыть",
    rules: [required],
    render: (text, record) => {
      return (
        <Checkbox
          checked={text === 1}
          onChange={e =>
            record.updateNews({
              ...record,
              newsActive: text === 1 ? 0 : 1
            })
          }
        />
      );
    }
  },
  {
    field: "newsText",
    title: "Текст",
    rules: [required],
    noShow: true
  },
  {
    field: "delete",
    title: "",
    width: 72,
    rules: [],
    render: (text, record) =>
      !record.noButton && (
        <Button
          className="btn-delete"
          shape="circle"
          icon="delete"
          size="large"
          onClick={() => {
            Modal.confirm({
              title: record.newsName,
              content: "Удалить новость",
              okText: "Да",
              okType: "danger",
              cancelText: "Нет",
              width: 276,
              zIndex: 10001,
              onOk() {
                record.deleteNews(record);
              },
              onCancel() {}
            });
          }}
        />
      )
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_NEWS, {
                contextData: record
              });
              openModal(MODAL_NEWS);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
