import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import "./style.scss";
import { EmailInput, Input, PhoneInput } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/orders";
import { AnyObject } from "../../../types";
import { OrdersSelector } from "../../../redux/selectors";

import { updateOrders } from "../../../redux/actions/orders";
import { OrderModels, Orders } from "../../../models/orders";
import TextArea from "antd/lib/input/TextArea";
import { Button, Checkbox, DatePicker, Modal, Select as AntSelect } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import { Select } from "../../inputs/select";
import { defined } from "../../../utils/define";
import {
  CATALOG_SMALL_PHOTO,
  getModelPhoto,
  ORDER_STATUSES,
  TIME_DATES
} from "../../../constants/main";
import { API_DOMAIN } from "../../../constants/api";
import moment from "moment";
import Materials from "../../../models/catalog/materials";
import Insets from "../../../models/catalog/insets";
import {
  getInsets,
  getMaterials,
  getModels
} from "../../../redux/actions/catalog";
import Models from "../../../models/catalog/models";

const { Option } = AntSelect;

interface Props extends FormProps {
  model: AnyObject;
  getModels: Function;
  updateOrders: Function;
  getMaterials: Function;
  getInsets: Function;
  message: AnyObject;
  searchModels: Models[];
  materials: Materials[];
  insets: Insets[];
}

interface State extends FormState {}

class OrdersForm extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {
      models: []
    },
    prices: {},
    loading: false,
    tooltipError: [],
    removeModels: [],
    imageUrl: "",
    searchModels: []
  };

  async componentDidMount() {
    this.props.getMaterials();
    this.props.getInsets();

    if (!!this.props.model && !!this.props.model.idOrder) {
      await this.setState({
        model: this.props.model
      });

      this.setState({ orderPrice: await this.setOrderPrice() });
    }
  }

  setOrderPrice = async () => {
    const orderPrice =
      Number(this.state.model.orderPaid) === 0
        ? this.state.model.models.reduce(
            (summ: number, model: AnyObject) =>
              summ +
              Number(model.orderPrice) * Number(model.orderCount) +
              Number(this.state.model.orderDeliveryPrice),
            0
          )
        : Number(this.state.model.orderPaid);

    await this.setState({
      model: {
        ...this.state.model,
        orderPrice: orderPrice
      }
    });

    return orderPrice;
  };

  handleSubmit = async () => {
    this.setState({ tooltipError: [] });
    this.submitForm(fields, async () => {
      let changedData = {} as AnyObject;

      Object.entries(this.state.model).forEach(([key, item], idx) => {
        if (JSON.stringify(item) !== JSON.stringify(this.props.model[key])) {
          if (key === "orderPrice") {
            if (Number(this.state.orderPrice) !== Number(item)) {
              changedData[key] = "сумма заказа";
            }
          } else {
            //@ts-ignore
            if (defined(formFields[key])) {
              //@ts-ignore
              changedData[key] = formFields[key].title;
            }
          }
        }
      });

      if (
        Object.keys(changedData).length > 0 ||
        Object.keys(this.state.prices).length > 0 ||
        this.state.removeModels.length > 0
      ) {
        return await this.props.updateOrders({
          model: this.state.model,
          prices: this.state.prices,
          changedData,
          removeModels: this.state.removeModels
        });
      } else {
        this.setState({ tooltipError: ["Нет изменений для сохранения"] });

        setTimeout(() => {
          this.setState({ tooltipError: [] });
        }, 2000);

        return "without message";
      }
    });
  };

  handleOnChangePrice = async (
    name: string,
    idOrderModel: number,
    val: any
  ) => {
    let currentPrice = this.state.prices;

    if (!defined(currentPrice[idOrderModel])) {
      currentPrice[idOrderModel] = {};
    }

    currentPrice[idOrderModel][name] = val;

    await this.setState({
      model: {
        ...this.state.model,
        models: this.state.model.models.map((item: OrderModels) =>
          item.idOrderModel === idOrderModel ? { ...item, [name]: val } : item
        )
      },
      prices: currentPrice
    });

    this.setOrderPrice();
  };

  handleOnChangeParam = async (
    name: string,
    idOrderModel: number,
    val: any
  ) => {
    let currentPrice = this.state.prices;

    if (!defined(currentPrice[idOrderModel])) {
      currentPrice[idOrderModel] = {};
    }

    if (!defined(currentPrice[idOrderModel]["params"])) {
      currentPrice[idOrderModel]["params"] = {};
    }

    currentPrice[idOrderModel]["params"][name] = val;

    await this.setState({
      model: {
        ...this.state.model,
        models: this.state.model.models.map(
          (item: OrderModels, idx: number) => {
            if (item.idOrderModel === idOrderModel) {
              const params = {
                ...item.params,
                [name]: val
              };

              currentPrice[idOrderModel]["params"] = params;

              return {
                ...item,
                params
              };
            }

            return item;
          }
        )
      },
      prices: currentPrice
    });

    //this.setOrderPrice();
  };

  deleteModel = (idOrderModel: number) => {
    this.setState({
      model: {
        ...this.state.model,
        models: this.state.model.models.filter(
          (item: OrderModels) => item.idOrderModel !== idOrderModel
        )
      },
      removeModels: [...this.state.removeModels, idOrderModel]
    });
  };

  handleAddNewModel = (idModel: string) => {
    const model = this.props.searchModels.find(
      model => Number(idModel) === Number(model.idModel)
    );

    this.setState({
      model: {
        ...this.state.model,
        models: [
          ...this.state.model.models,
          {
            ...(model as OrderModels),
            orderPrice: 0,
            orderCount: 0,
            orderSize: ""
          }
        ]
      }
    });
  };

  handleSearch = async (text: string) => {
    if (text.length > 2) {
      await this.props.getModels({ search: text, getAll: true });
      this.setState({ searchModels: this.props.searchModels });
    } else {
      this.setState({ searchModels: [] });
    }
  };

  render() {
    const fields = formFields as AnyObject;
    const searchModels = this.state.searchModels.map((model: Models) => (
      <Option key={model.idModel}>{model.modelName}</Option>
    ));

    return (
      <div className="app-form edit-order">
        <div className="block">
          <div className="column">
            <label>
              {fields.userEmail.title}:
              <EmailInput {...this.getStandartProps(fields.userEmail)} />
            </label>

            <label>
              {fields.userPhone.title}:
              <PhoneInput {...this.getStandartProps(fields.userPhone)} />
            </label>
          </div>

          <div>
            <label>
              {fields.orderDeliveryAddress.title}:
              <TextArea
                {...this.getStandartProps(fields.orderDeliveryAddress)}
              />
            </label>
          </div>

          <div>
            <div className={"column"}>
              <Checkbox
                checked={Number(this.state.model.orderDelivery) === 1}
                onChange={() => {
                  this.handleOnChangeInput("orderDelivery", 1);
                }}
              >
                Доставка
              </Checkbox>
              <Checkbox
                checked={Number(this.state.model.orderDelivery) === 2}
                onChange={() => {
                  this.handleOnChangeInput("orderDelivery", 2);
                }}
              >
                Самовывоз
              </Checkbox>
              <Checkbox
                checked={Number(this.state.model.orderPayment) === 1}
                onChange={() => {
                  this.handleOnChangeInput("orderPayment", 1);
                }}
              >
                Оплата при получении
              </Checkbox>
              <Checkbox
                checked={Number(this.state.model.orderPayment) === 2}
                onChange={() => {
                  this.handleOnChangeInput("orderPayment", 2);
                }}
              >
                Оплата онлайн
              </Checkbox>
            </div>
          </div>

          <div className={"column"}>
            <label>
              {fields.orderDeliveryDate.title}:{" "}
              {this.getTooltipError("orderDeliveryTime")}
              <br />
              <DatePicker
                locale={locale}
                value={moment(this.state.model.orderDeliveryDate, "YYYY-MM-DD")}
                onChange={(date, dateString) =>
                  this.handleOnChangeInput("orderDeliveryDate", dateString)
                }
              />
            </label>
            <label>
              {fields.orderDeliveryTime.title}:
              {this.getTooltipError("orderDeliveryTime")}
              <Select
                name={"orderDeliveryTime"}
                options={TIME_DATES}
                labelGetter={(item: string) => item}
                valueGetter={(item: string) =>
                  item === "Не указано" ? "" : item
                }
                placeholder={fields.orderDeliveryTime.title}
                value={
                  defined(this.state.model.orderDeliveryTime)
                    ? this.state.model.orderDeliveryTime
                    : undefined
                }
                onChange={(val: number) =>
                  this.handleOnChangeInput("orderDeliveryTime", val)
                }
              />
            </label>
            <label>
              {fields.orderStatus.title}:{this.getTooltipError("orderStatus")}
              <Select
                name={"orderStatus"}
                options={ORDER_STATUSES}
                labelGetter={(item: AnyObject) => item.name}
                placeholder={fields.orderStatus.title}
                value={
                  defined(this.state.model.orderStatus)
                    ? Number(this.state.model.orderStatus)
                    : undefined
                }
                onChange={(val: number) =>
                  this.handleOnChangeInput("orderStatus", val)
                }
              />
            </label>
          </div>

          <div className={"column"}>
            <label>
              {fields.orderPaid.title}:
              <Input {...this.getStandartProps(fields.orderPaid)} />
            </label>
            <label>
              {fields.orderDeliveryPrice.title}:
              <Input {...this.getStandartProps(fields.orderDeliveryPrice)} />
            </label>
            <label>
              {fields.orderPrice.title}:
              <Input {...this.getStandartProps(fields.orderPrice)} />
            </label>
          </div>
        </div>

        <table className={`basket-items`}>
          <tbody>
            {this.state.model.models.map((model: AnyObject, idx: number) => {
              return (
                <React.Fragment key={`model-${idx}`}>
                  {idx === 0 && (
                    <tr>
                      <th />
                      <th />
                      <th>Размер</th>
                      <th>Количество</th>
                      <th>Стоимость</th>
                    </tr>
                  )}
                  <tr className={"item"}>
                    <td>
                      <img
                        height={100}
                        src={`${API_DOMAIN}${CATALOG_SMALL_PHOTO}${
                          getModelPhoto(model.modelPhoto)[0]
                        }`}
                      />
                    </td>
                    <td className={"description"}>
                      <div className={"name"}>{model.modelName}</div>
                      <div>Артикул: {model.modelCode}</div>
                      {!!model.params && (
                        <>
                          <div>
                            <label>Материал:</label>
                            <Select
                              name={`material`}
                              value={model.params.material}
                              options={this.props.materials}
                              valueGetter={(option: Materials) =>
                                option.materialName
                              }
                              labelGetter={(option: Materials) =>
                                option.materialName
                              }
                              onChange={(value: number) =>
                                this.handleOnChangeParam(
                                  "material",
                                  model.idOrderModel,
                                  value
                                )
                              }
                            />
                          </div>

                          {model.params.insets.length > 0 && (
                            <>
                              <label>Вставки:</label>
                              <div className="order-insets">
                                {model.params.insets.map(
                                  (inset: string, idxInset: number) => (
                                    <Select
                                      key={`indsets-${idxInset}`}
                                      name={`indsets`}
                                      value={inset}
                                      options={this.props.insets}
                                      valueGetter={(option: Insets) =>
                                        option.insetName
                                      }
                                      labelGetter={(option: Insets) =>
                                        option.insetName
                                      }
                                      onChange={(value: string) => {
                                        this.handleOnChangeParam(
                                          "insets",
                                          model.idOrderModel,
                                          model.params.insets.map(
                                            (item: string, idInset: number) =>
                                              idInset === idxInset
                                                ? value
                                                : item
                                          )
                                        );
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </td>
                    {model.params ? (
                      <>
                        <td>
                          <Input
                            value={model.params.sizes}
                            name={"paramsSize"}
                            placeholder="Размер"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangeParam(
                                "sizes",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={model.orderCount}
                            name={"orderCount"}
                            placeholder="Кол-во"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangePrice(
                                "orderCount",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={model.orderManagerPrice}
                            name={"orderManagerPrice"}
                            placeholder="Цена"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangePrice(
                                "orderManagerPrice",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <Input
                            value={model.orderSize}
                            name={"orderSize"}
                            placeholder="Размер"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangePrice(
                                "orderSize",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={model.orderCount}
                            name={"orderCount"}
                            placeholder="Кол-во"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangePrice(
                                "orderCount",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={
                              Number(model.orderManagerPrice) !== 0
                                ? model.orderManagerPrice
                                : model.orderPrice * model.orderCount
                            }
                            name={"orderManagerPrice"}
                            placeholder="Цена"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.handleOnChangePrice(
                                "orderManagerPrice",
                                model.idOrderModel,
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </>
                    )}
                    <td>
                      <Button
                        className="btn-delete"
                        shape="circle"
                        icon="delete"
                        size="large"
                        onClick={() => {
                          Modal.confirm({
                            title: model.modelName,
                            content: "Удалить модель",
                            okText: "Да",
                            okType: "danger",
                            cancelText: "Нет",
                            width: 276,
                            zIndex: 10001,
                            onOk: () => {
                              this.deleteModel(model.idOrderModel);
                            },
                            onCancel() {}
                          });
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <label>
          Добавить изделие
          <br />
          <AntSelect
            value={undefined}
            showArrow={true}
            showSearch={true}
            placeholder={`Поиск по артикулу (минимум 3 символа)`}
            defaultActiveFirstOption={false}
            filterOption={false}
            onChange={this.handleAddNewModel}
            onSearch={this.handleSearch}
            style={{ width: "50%" }}
            notFoundContent={null}
          >
            {searchModels}
          </AntSelect>
        </label>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(OrdersSelector, {
  getModels,
  updateOrders,
  getInsets,
  getMaterials
})(OrdersForm);
