import { required, validEmail, validPhone } from "../../reactive";
import React from "react";
import {
  MODAL_CATALOG_MATERIALS,
  MODAL_CATALOG_MODELS
} from "../../../constants/modal";
import { ModalsConsumer } from "components/modals/context";
import { Button, Modal } from "antd";
import nophoto from "img/nophoto.png";
import { defined } from "../../define";
import { API_DOMAIN } from "../../../constants/api";
import { CATALOG_SMALL_PHOTO } from "../../../constants/main";

export const fields = [
  {
    field: "modelPhoto",
    title: "Фото",
    render: text => {
      const photo = text.split("||");
      return (
        <img
          width={80}
          src={
            photo.length > 0
              ? `${API_DOMAIN}${CATALOG_SMALL_PHOTO}${photo[0]}`
              : nophoto
          }
        />
      );
    }
  },
  {
    field: "modelCode",
    title: "Артикул",
    rules: [required]
  },
  {
    field: "idCategory",
    title: "Категория",
    rules: [required],
    noShow: true,
    render: (text, record) =>
      defined(record.category) ? record.category.categoryName : text
  },
  {
    field: "modelInsets",
    title: "Вставка",
    render: (text, record) => {
      return defined(record.insets)
        ? record.insets.map(inset => (defined(inset) ? inset.insetName : "")).join(', ')
        : "";
    }
  },
  {
    field: "modelName",
    title: "Название изделия",
    noShow: true,
    rules: [required]
  },
  {
    field: "modelWeight",
    title: "Вес",
    rules: [required]
  },
  {
    field: "idMaterial",
    title: "Материал",
    rules: [required],
    noShow: true,
    render: (text, record) =>
      defined(record.material) ? record.material.materialName : text
  },
  {
    field: "modelDescription",
    title: "Описание",
    noShow: true
  },
  {
    field: "delete",
    title: "",
    width: 72,
    rules: [],
    render: (text, record) =>
      !record.noButton && (
        <Button
          className="btn-delete"
          shape="circle"
          icon="delete"
          size="large"
          onClick={() => {
            Modal.confirm({
              title: record.materialName,
              content: "Удалить изделие",
              okText: "Да",
              okType: "danger",
              cancelText: "Нет",
              width: 276,
              zIndex: 10001,
              onOk() {
                record.deleteModel(record);
              },
              onCancel() {}
            });
          }}
        />
      )
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_CATALOG_MODELS, {
                contextData: record
              });
              openModal(MODAL_CATALOG_MODELS);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
