import { required, validEmail, validPhone } from "../reactive";
import React from "react";

export const fields = [
	{
		field: "bannerPhoto",
		title: "Картинка",
		rules: [required],
	},{
		field: "bannerName",
		title: "Название акции",
		rules: [required],
	},
	{
		field: "bannerUrl",
		title: "Ссылка",
		rules: [],
	},
	{
		field: "bannerOrder",
		title: "Сортировка",
		rules: [],
	},
	{
		field: "bannerText",
		title: "Текст",
		rules: [],
	},
];

export const formFields = fields.reduce(
	(acc, item) => ({ ...acc, [item.field]: item }),
	{}
);