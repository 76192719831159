import { NAV_ACTIONS } from '../types';

import { sideMenu } from 'utils/menu';

const viewChanged = data => ({
  type: NAV_ACTIONS.CHANGED,
  data
});

export const changeView = viewName => (dispatch, getState) => {
  const { navigation } = getState();
  const menu = sideMenu.find(s => {
    if (s.view === viewName) {
      return s.view === viewName;
    } else {
      return s.items.some(it => it.view === viewName);
    }
  });

  let data = {
    sub: menu.items,
    next: menu.view,
    prev: navigation.current
  };

  if (navigation.current !== data.next) {
    dispatch(viewChanged(data));
  }

  return Promise.resolve();
};

export const getView = viewPath => {
  return sideMenu.reduce((acc, s) => {
    if (s.view === viewPath) {
      return s;
    } else {
      const item = s.items.find(it => it.path === viewPath);
      return !!item ? item : acc
    }
  }, {});
};