import { API, API_CALL } from "../types";
import { logout } from "./user";

export const apiStarted = label => ({
  type: API.STARTED,
  payload: label
});

export const apiEnded = label => ({
  type: API.ENDED,
  payload: label
});

export const accessDenied = url => ({
  type: API.ACCESS_DENIED,
  payload: {
    url
  }
});

export const apiError = error => ({
  type: API.ERROR,
  error
});

export const accessNotAuthorized = url => dispatch => {
  dispatch(logout());
};

export const apiAction = ({
  requestConfig = {
    method: "get",
    data: undefined,
    url: "",
    headers: {}
  },
  onSuccess = () => {},
  onFailure = () => {},
  label = undefined
}) => ({
  type: API_CALL,
  payload: {
    requestConfig,
    onSuccess,
    onFailure,
    label
  }
});