import React from "react";
import {Upload, Icon} from "antd";
import message from "components/message";
import {defined} from "../../utils/define";

function beforeUpload(file) {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
	const isLt2M = file.size / 1024 / 1024 < 6;

	if (!isJpgOrPng) {
		message.error("Возможно загружать только JPG/PNG файлы!");
	} else if (!isLt2M) {
		message.error("Фото должно быть меньше 6MB!");
	}

	return isJpgOrPng && isLt2M;
}

export default class Photo extends React.Component {
	state = {
		loading: false
	};

	hsndleRequest = async info => {
		this.props.loading(true);
		let data = new FormData();
		await data.append("file", info.file);

		if (defined(this.props.filePath)) {
			await data.append("filePath", this.props.filePath);
		}

		if (defined(this.props.width)) {
			await data.append("width", this.props.width);
		}

		if (defined(this.props.height)) {
			await data.append("height", this.props.height);
		}

		if (defined(this.props.smallParams)) {
			await data.append("smallParamsWidth", this.props.smallParams.width);
			await data.append("smallParamsHeight", this.props.smallParams.height);
		}

		await this.props.setPhoto(data);

		await this.props.onChange();
		this.props.loading(false);
	};

	render() {
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? "loading" : "plus"}/>
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const {imageUrl} = this.props;

		return (
			<Upload
				name="photo"
				listType="picture-card"
				className="photo-uploader"
				showUploadList={false}
				action={this.props.action}
				beforeUpload={beforeUpload}
				onChange={this.handleChange}
				customRequest={this.hsndleRequest}
			>
				{imageUrl ? (
					<img src={imageUrl} style={{width: "100%"}}/>
				) : (
					uploadButton
				)}
			</Upload>
		);
	}
}
