import { userDefault } from "./defaultStates";
import { USER_ACTIONS } from "../types";
import { UserStore } from "../../types/redux";
import { AnyAction } from "redux";

const auth = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.LOGIN:
      return {
        ...state,
        ...action.user,
        loggedIn: true
      };
    case USER_ACTIONS.LOGOUT:
      return state;
    default:
      return state;
  }
};

const errors = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case USER_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

export default (state = userDefault, action: AnyAction): UserStore => {
  return {
    user: auth(state.user, action),
    error: errors(state.error, action)
  };
};
