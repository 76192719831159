import { CATALOG_ACTIONS, NEWS_ACTIONS } from "../types";
import { CATALOG, PHOTOS } from "../../api/endpoints";
import { apiAction } from "./api";
import { defined } from "../../utils/define";

const setCategories = categories => ({
  type: CATALOG_ACTIONS.SET_CATEGORIES,
  categories
});

const setMaterials = materials => ({
  type: CATALOG_ACTIONS.SET_MATERIALS,
  materials
});

const setSizes = sizes => ({
  type: CATALOG_ACTIONS.SET_SIZES,
  sizes
});

const setInsets = insets => ({
  type: CATALOG_ACTIONS.SET_INSETS,
  insets
});

const addOneMaterial = material => ({
  type: CATALOG_ACTIONS.SET_ONE_MATERIAL,
  material
});

const updateOneMaterial = material => ({
  type: CATALOG_ACTIONS.UPDATE_ONE_MATERIAL,
  material
});

const addOneCategory = category => ({
  type: CATALOG_ACTIONS.SET_ONE_CATEGORY,
  category
});

const updateOneCategory = category => ({
  type: CATALOG_ACTIONS.UPDATE_ONE_CATEGORY,
  category
});

const addOneSize = size => ({
  type: CATALOG_ACTIONS.SET_ONE_SIZE,
  size
});

const addOneInset = inset => ({
  type: CATALOG_ACTIONS.SET_ONE_INSET,
  inset
});

const updateOneInset = inset => ({
  type: CATALOG_ACTIONS.UPDATE_ONE_INSET,
  inset
});

const deleteOneInset = inset => ({
  type: CATALOG_ACTIONS.DELETE_ONE_INSET,
  inset
});

const updateModelInsetRelations = data => ({
  type: CATALOG_ACTIONS.UPDATE_INSET_RELATION,
  data
});

const updateModelPrices = (data, idModel) => ({
  type: CATALOG_ACTIONS.UPDATE_PRICES,
  data: { prices: data, idModel: idModel }
});

const updateOneSize = size => ({
  type: CATALOG_ACTIONS.UPDATE_ONE_SIZE,
  size
});

const addOneModel = model => ({
  type: CATALOG_ACTIONS.SET_ONE_MODEL,
  model
});

const updateOneModel = model => ({
  type: CATALOG_ACTIONS.UPDATE_ONE_MODEL,
  model
});

const deleteOneModel = model => ({
  type: CATALOG_ACTIONS.DELETE_ONE_MODEL,
  model
});

const deleteOneSize = size => ({
  type: CATALOG_ACTIONS.DELETE_ONE_SIZE,
  size
});

const deleteOneCategory = category => ({
  type: CATALOG_ACTIONS.DELETE_ONE_CATEGORY,
  category
});

const deleteOneMaterial = material => ({
  type: CATALOG_ACTIONS.DELETE_ONE_MATERIAL,
  material
});

const setCatalog = models => ({
  type: CATALOG_ACTIONS.SET_MODELS,
  models
});

const setMessage = message => ({
  type: CATALOG_ACTIONS.MESSAGE,
  message
});

const error = error => ({
  type: CATALOG_ACTIONS.ERROR,
  error
});

export const getCategories = () => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_CATEGORIES(),
      onSuccess: data => setCategories(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const getMaterials = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_MATERIALS(data),
      onSuccess: data => setMaterials(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const getSizes = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_SIZES(data),
      onSuccess: data => setSizes(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const getInsets = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_INSETS(data),
      onSuccess: data => setInsets(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const addMaterial = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.ADD_MATERIAL(data),
      onSuccess: data => addOneMaterial(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateMaterial = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_MATERIAL(data),
      onSuccess: data => updateOneMaterial(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const deleteMaterial = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.DELETE_MATERIAL(data),
      onSuccess: () => deleteOneMaterial(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const addCategory = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.ADD_CATEGORY(data),
      onSuccess: data => addOneCategory(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateCategory = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_CATEGORY(data),
      onSuccess: data => updateOneCategory(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const deleteCategory = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.DELETE_CATEGORY(data),
      onSuccess: () => deleteOneCategory(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const addSize = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.ADD_SIZE(data),
      onSuccess: data => addOneSize(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateSize = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_SIZE(data),
      onSuccess: data => updateOneSize(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const deleteSize = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.DELETE_SIZE(data),
      onSuccess: () => deleteOneSize(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const addInset = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.ADD_INSET(data),
      onSuccess: data => addOneInset(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateInset = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_INSET(data),
      onSuccess: data => updateOneInset(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const deleteInset = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.DELETE_INSET(data),
      onSuccess: () => deleteOneInset(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateInsetRelations = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_INSET_RELATIONS(data),
      onSuccess: () => updateModelInsetRelations(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updatePrices = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_PRICES(data),
      onSuccess: prices => updateModelPrices(prices, data.idModel),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const getModels = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.GET_MODELS(data),
      onSuccess: data => setCatalog(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const updateModel = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.UPDATE_MODEL(data),
      onSuccess: data => updateOneModel(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const addModel = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.ADD_MODEL(data),
      onSuccess: data => addOneModel(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const deleteModel = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: CATALOG.DELETE_MODEL(data),
      onSuccess: () => deleteOneModel(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};

export const setPhoto = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PHOTOS.SET_PHOTO(data, "catalog"),
      onSuccess: data => setMessage(data),
      onFailure: e =>
        defined(e.response) && defined(e.response.data)
          ? error(e.response.data)
          : ""
    })
  );
};
