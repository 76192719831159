import { catalogDefault } from "./defaultStates";
import { CATALOG_ACTIONS, NEWS_ACTIONS, PAGES_ACTIONS } from "../types";

const categories = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_CATEGORIES:
      return action.categories;
    case CATALOG_ACTIONS.SET_ONE_CATEGORY:
      return [action.category, ...state];
    case CATALOG_ACTIONS.UPDATE_ONE_CATEGORY:
      return state.map(item =>
        item.idCategory === action.category.idCategory ? action.category : item
      );
    case CATALOG_ACTIONS.DELETE_ONE_CATEGORY:
      return state.filter(
        item => item.idCategory !== action.category.idCategory
      );
    default:
      return state;
  }
};

const materials = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MATERIALS:
      return action.materials;
    case CATALOG_ACTIONS.SET_ONE_MATERIAL:
      return [action.material, ...state];
    case CATALOG_ACTIONS.UPDATE_ONE_MATERIAL:
      return state.map(item =>
        item.idMaterial === action.material.idMaterial ? action.material : item
      );
    case CATALOG_ACTIONS.DELETE_ONE_MATERIAL:
      return state.filter(
        item => item.idMaterial !== action.material.idMaterial
      );
    default:
      return state;
  }
};

const sizes = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_SIZES:
      return action.sizes;
    case CATALOG_ACTIONS.SET_ONE_SIZE:
      return [action.size, ...state];
    case CATALOG_ACTIONS.UPDATE_ONE_SIZE:
      return state.map(item =>
        item.idSize === action.size.idSize ? action.size : item
      );
    case CATALOG_ACTIONS.DELETE_ONE_SIZE:
      return state.filter(item => item.idSize !== action.size.idSize);
    default:
      return state;
  }
};

const models = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_MODELS:
      return action.models.map(model => ({
        ...model,
        modelInsets: model.insets.map(inset => Number(inset.idInset))
      }));
    case CATALOG_ACTIONS.UPDATE_INSET_RELATION:
      return state.map(model =>
        Number(action.data.idModel) !== Number(model.idModel)
          ? model
          : {
              ...model,
              modelInsets: action.data.modelInsets
            }
      );
    case CATALOG_ACTIONS.UPDATE_PRICES:
      return state.map(model =>
        Number(action.data.idModel) !== Number(model.idModel)
          ? model
          : {
              ...model,
              prices: action.data.prices
            }
      );
    case CATALOG_ACTIONS.SET_ONE_MODEL:
      return [action.model, ...state];
    case CATALOG_ACTIONS.UPDATE_ONE_MODEL:
      return state.map(item => Number(item.idModel) === Number(action.model.idModel) ? {...item, ...action.model} : item
      );
    case CATALOG_ACTIONS.DELETE_ONE_MODEL:
      return state.filter(item => Number(item.idModel) !== Number(action.model.idModel));
    default:
      return state;
  }
};

const insets = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.SET_INSETS:
      return action.insets;
    case CATALOG_ACTIONS.SET_ONE_INSET:
      return [action.inset, ...state];
    case CATALOG_ACTIONS.UPDATE_ONE_INSET:
      return state.map(item =>
        item.idInset === action.inset.idInset ? action.inset : item
      );
    case CATALOG_ACTIONS.DELETE_ONE_INSET:
      return state.filter(item => item.idInset !== action.inset.idInset);
    default:
      return state;
  }
};

const message = (state, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.MESSAGE:
      return action.message;
    default:
      return state;
  }
};

const error = (state = {}, action = {}) => {
  switch (action.type) {
    case CATALOG_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

export default (state = catalogDefault, action = {}) => {
  return {
    insets: insets(state.insets, action),
    categories: categories(state.categories, action),
    materials: materials(state.materials, action),
    models: models(state.models, action),
    sizes: sizes(state.sizes, action),
    message: message(state.message, action),
    error: error(state.error, action)
  };
};
