/**
 * API ACTION TYPES
 */
export const API_CALL = Symbol("API_CALL");
export const API = {
  ACCESS_DENIED: "API_ACCESS_DENIED",
  ERROR: "API_ERROR",
  STARTED: "API_STARTED",
  ENDED: "API_ENDED"
};

/**
 * NAV ACTION TYPES
 */
export const NAV_ACTIONS = {
  CHANGED: "VIEW_CHANGED"
};

/**
 * USER ACTION TYPES
 */
export const USER_ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ERROR: "ERROR_USERS"
};

/**
 * BANNERS ACTION TYPES
 */
export const BANNERS_ACTIONS = {
  GET: "GET_BANNERS",
  SET: "SET_BANNERS",
  ERROR: "ERROR_BANNERS",
  MESSAGE: "MESSAGE_BANNERS",
  ADD: "ADD_BANNERS",
  UPDATE: "UPDATE_BANNERS",
  REMOVE: "REMOVE_BANNERS"
};

/**
 * PAGES ACTION TYPES
 */
export const PAGES_ACTIONS = {
  GET: "GET_PAGES",
  SET: "SET_PAGES",
  ERROR: "ERROR_PAGES",
  MESSAGE: "MESSAGE_PAGES",
  ADD: "ADD_PAGES",
  UPDATE: "UPDATE_PAGES",
  REMOVE: "REMOVE_PAGES"
};

/**
 * PAGES ACTION TYPES
 */
export const NEWS_ACTIONS = {
  GET: "GET_NEWS",
  SET: "SET_NEWS",
  ERROR: "ERROR_NEWS",
  MESSAGE: "MESSAGE_NEWS",
  ADD: "ADD_NEWS",
  UPDATE: "UPDATE_NEWS",
  REMOVE: "REMOVE_NEWS"
};

/**
 * PAGES ACTION TYPES
 */
export const ORDERS_ACTIONS = {
  GET: "GET_ORDERS",
  SET: "SET_ORDERS",
  SET_COUNTS: "SET_COUNTS_ORDERS",
  ERROR: "ERROR_ORDERS",
  MESSAGE: "MESSAGE_ORDERS",
  ADD: "ADD_ORDERS",
  UPDATE: "UPDATE_ORDERS",
  REMOVE: "REMOVE_ORDERS"
};

/**
 * CATALOG ACTION TYPES
 */
export const CATALOG_ACTIONS = {
  SET_CATEGORIES: "SET_CATEGORIES_CATALOG",
  SET_MATERIALS: "SET_MATERIALS_CATALOG",
  SET_SIZES: "SET_SIZES_CATALOG",
  SET_INSETS: "SET_INSETS_CATALOG",
  SET_ONE_MATERIAL: "SET_ONE_MATERIAL_CATALOG",
  UPDATE_ONE_MATERIAL: "UPDATE_ONE_MATERIAL_CATALOG",
  SET_ONE_CATEGORY: "SET_ONE_CATEGORY_CATALOG",
  UPDATE_ONE_CATEGORY: "UPDATE_ONE_CATEGORY_CATALOG",
  SET_ONE_SIZE: "SET_ONE_SIZE_CATALOG",
  SET_ONE_INSET: "SET_ONE_INSET_CATALOG",
  DELETE_ONE_INSET: "DELETE_ONE_INSET_CATALOG",
  UPDATE_ONE_INSET: "UPDATE_ONE_INSET_CATALOG",
  UPDATE_ONE_SIZE: "UPDATE_ONE_SIZE_CATALOG",
  DELETE_ONE_SIZE: "DELETE_ONE_SIZE_CATALOG",
  DELETE_ONE_CATEGORY: "DELETE_ONE_CATEGORY_CATALOG",
  DELETE_ONE_MATERIAL: "DELETE_ONE_MATERIAL_CATALOG",
  SET_MODELS: "SET_MODELS_CATALOG",
  UPDATE_ONE_MODEL: "UPDATE_ONE_MODEL_CATALOG",
  SET_ONE_MODEL: "SET_ONE_MODEL_CATALOG",
  MESSAGE: "MESSAGE_CATALOG",
  ERROR: "ERROR_CATALOG",
  DELETE_ONE_MODEL: "DELETE_ONE_MODEL_CATALOG",
  UPDATE_INSET_RELATION: "UPDATE_INSET_RELATION_CATALOG",
  UPDATE_PRICES: "UPDATE_PRICES_CATALOG"
};
