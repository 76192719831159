import { Menu } from "../../types/menu";

export const sideMenu: Menu[] = [
  {
    name: "main",
    items: [
      {
        title: "Вход в систему",
        path: `/`,
        view: "Login",
        public: true,
        rules: []
      }
    ]
  },
  {
    name: "header",
    items: [
      {
        title: "Баннеры",
        path: `/banners`,
        view: "Banners",
        rules: [1,2]
      },
      {
        title: "Страницы",
        path: `/pages`,
        view: "Pages",
        rules: [1]
      },
      {
        title: "Новости",
        path: `/news`,
        view: "News",
        rules: [1,2]
      },
      {
        title: "Каталог",
        path: `/catalog`,
        view: "Catalog",
        rules: [1,3]
      },
      {
        title: "Каталог",
        path: `/catalog-models/:category`,
        view: "Catalog",
        noMenu: false,
        rules: [1]
      },
      {
        title: "Заказы",
        path: `/orders/`,
        view: "Orders",
        rules: [1, 2]
      },
      {
        title: "Заказы",
        path: `/orders-by-status/:order`,
        view: "Orders",
        noMenu: false,
        rules: [1]
      }
    ]
  }
];
