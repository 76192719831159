export const DEFAULT_MODAL_Z_INDEX = 10000;

export const MODAL_BANNERS = 'modal_banners';
export const MODAL_PAGES = 'modal_pages';
export const MODAL_NEWS = 'modal_news';
export const MODAL_CATALOG = 'modal_catalog';
export const MODAL_CATALOG_CATEGORY = 'modal_catalog_category';
export const MODAL_CATALOG_MATERIALS = 'modal_catalog_materials';
export const MODAL_CATALOG_SIZES = 'modal_catalog_sizes';
export const MODAL_CATALOG_MODELS = 'modal_catalog_models';
export const MODAL_CATALOG_INSETS = 'modal_catalog_insets';
export const MODAL_ORDERS = 'modal_orders';