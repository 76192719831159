import md5 from "js-md5";
import Auth from "api/auth";
import { clearStorage } from "utils/local-storage";
import { USER_ACTIONS } from "../types";
import { changeView } from "./navigation";

const userLogIn = user => ({
  type: USER_ACTIONS.LOGIN,
  user
});

const userLogOut = () => ({
  type: USER_ACTIONS.LOGOUT
});

const userError = error => {
  return ({
    type: USER_ACTIONS.ERROR,
    error
  });
}

export const userLogin = ({ username, password }) => dispatch =>
  Auth.login({ username: username, password: md5(password) })
    .then(user => {
      dispatch(userError(""));
      dispatch(userLogIn(user));
      dispatch(changeView("Index"));
    })
    .catch(e =>
      dispatch(
        userError(
          !!e.response
            ? e.response
            : "Нет связи с сервером, попробуйте позже."
        )
      )
    );

export const logout = () => dispatch => {
  Auth.logout().then(() => {
    dispatch(userLogOut());
    clearStorage();
  });

  return Promise.resolve();
};