import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/catalog/materials";
import { AnyObject } from "../../../types";
import { PagesSelector } from "../../../redux/selectors";
import { addMaterial, updateMaterial } from "../../../redux/actions/catalog";
import Materials from "../../../models/catalog/materials";

interface Props extends FormProps {
  model: Materials;
  updateMaterial: (data: Materials) => void;
  addMaterial: (data: Materials) => void;
  message: AnyObject;
  materials: Materials[];
}

interface State extends FormState {}

class MaterialsAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {} as Materials,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idMaterial) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idMaterial
        ? await this.props.updateMaterial(this.state.model)
        : await this.props.addMaterial(this.state.model)
    );
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-pages">
        <div className="block">
          <label>
            {fields.materialName.title}:
            <Input {...this.getStandartProps(fields.materialName)} />
          </label>
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(PagesSelector, { addMaterial, updateMaterial })(
  MaterialsAddEdit
);
