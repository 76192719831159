export const DATE_FORMAT = "DD.MM.YYYY";
export const DAY_TIME_FORMAT = "DD.MM.YYYY";

export const NEWS_PHOTO = "/uploads/news/";
export const BANNERS_PHOTO = "/uploads/banners/";
export const CATALOG_PHOTO = "/uploads/models/";
export const CATALOG_SMALL_PHOTO = "/uploads/models/small/";

export const ORDER_STATUSES = [
  {
    name: "Принят",
    class: "gray"
  },
  {
    name: "Не оплачен",
    class: "red"
  },
  {
    name: "Оплачен",
    class: "green"
  },
  {
    name: "Обрабатывается",
    class: "gray"
  },
  {
    name: "Не смогли дозвониться",
    class: "red"
  },
  {
    name: "Доставка",
    class: "green"
  },
  {
    name: "Выполнен",
    class: "green"
  },
  {
    name: "Отменен",
    class: "red"
  },
  {
    name: "Ожидает в магазине",
    class: "green"
  }
];

export const TIME_DATES = [
  "Не указано",
  "9:00 - 14:00",
  "14:00 - 19:00",
  "19:00 - 22:00",
  "В течение дня"
];

export const getModelPhoto = item => {
  const photos = !!item ? item.split("||") : [''];

  return photos.length === 1 && photos[0] === "" ? ["nophoto.png"] : photos;
};
