import { required, validEmail, validPhone } from "../reactive";
import React from "react";
import { MODAL_PAGES } from "../../constants/modal";
import { ModalsConsumer } from "../../components/modals/context";
import { Button } from "antd";
import {defined} from "../define";

export const fields = [
  {
    field: "pageName",
    title: "Название страницы",
    rules: [required]
  },
  {
    field: "pageOrder",
    title: "Сортировка",
    rules: []
  },
  {
    field: "pageText",
    title: "Текст",
    rules: [required],
    noShow: true
  },
  {
    field: "pageParent",
    title: "Раздел сайта",
    rules: [required],
    render: (text, record) =>
      defined(record.pageParentData) ? record.pageParentData.pageName : ""
  },
  {
    field: "pagePath",
    title: "Название в URL",
    rules: [required]
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_PAGES, {
                contextData: record
              });
              openModal(MODAL_PAGES);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
