import { pagesDefault } from "./defaultStates";
import { PAGES_ACTIONS } from "../types";

const pages = (state, action = {}) => {
  switch (action.type) {
    case PAGES_ACTIONS.ADD:
      return [...state, action.page];
    case PAGES_ACTIONS.UPDATE:
      return state.map(item =>
        item.idPage === action.page.idPage ? action.page : item
      );
    case PAGES_ACTIONS.REMOVE:
      return state.filter(item => item.idPage !== action.page.idPage);
    case PAGES_ACTIONS.SET:
      return action.pages;
    default:
      return state;
  }
};

const error = (state = {}, action = {}) => {
  switch (action.type) {
    case PAGES_ACTIONS.ERROR:
      return action.error;
    default:
      return state;
  }
};

const message = (state, action = {}) => {
  switch (action.type) {
    case PAGES_ACTIONS.MESSAGE:
      return action.message;
    default:
      return state;
  }
};

export default (state = pagesDefault, action = {}) => {
  return {
    pages: pages(state.pages, action),
    message: message(state.message, action),
    error: error(state.error, action)
  };
};
