import { NEWS_ACTIONS } from "../types";
import { NEWS, PHOTOS } from "../../api/endpoints";
import { apiAction } from "./api";

const setNews = news => ({
  type: NEWS_ACTIONS.SET,
  news
});

const setMessage = message => ({
  type: NEWS_ACTIONS.MESSAGE,
  message
});

const setOneNews = news => ({
  type: NEWS_ACTIONS.ADD,
  news
});

const putNews = news => ({
  type: NEWS_ACTIONS.UPDATE,
  news
});

const removeNews = news => ({
  type: NEWS_ACTIONS.REMOVE,
  news
});

const errorNews = error => ({
  type: NEWS_ACTIONS.ERROR,
  error
});

export const getNews = () => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: NEWS.GET(),
      onSuccess: data => setNews(data),
      onFailure: e => e
    })
  );
};

export const addNews = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: NEWS.ADD(data),
      onSuccess: data => setOneNews(data),
      onFailure: e => errorNews(e.response.data)
    })
  );
};

export const updateNews = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: NEWS.UPDATE(data),
      onSuccess: () => putNews(data),
      onFailure: e => errorNews(e.response.data)
    })
  );
};

export const deleteNews = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: NEWS.DELETE(data),
      onSuccess: () => removeNews(data),
      onFailure: e => errorNews(e.response.data)
    })
  );
};

export const setPhoto = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PHOTOS.SET_PHOTO(data, "news"),
      onSuccess: data => setMessage(data),
      onFailure: e => errorNews(e.response.data)
    })
  );
};
