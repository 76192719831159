import { ORDERS_ACTIONS } from "../types";
import { ORDERS, PHOTOS } from "../../api/endpoints";
import { apiAction } from "./api";

const setOrders = orders => ({
  type: ORDERS_ACTIONS.SET,
  orders
});

const setCounts = orders => ({
  type: ORDERS_ACTIONS.SET_COUNTS,
  orders
});

const setOneOrders = orders => ({
  type: ORDERS_ACTIONS.ADD,
  orders
});

const putOrders = orders => ({
  type: ORDERS_ACTIONS.UPDATE,
  orders
});

const errorOrders = error => ({
  type: ORDERS_ACTIONS.ERROR,
  error
});

export const getOrders = (status) => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: ORDERS.GET({status: status}),
      onSuccess: data => setOrders(data),
      onFailure: e => e
    })
  );
};

export const getOrdersCount = () => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: ORDERS.GET({count: 1}),
      onSuccess: data => setCounts(data),
      onFailure: e => e
    })
  );
};

export const updateOrders = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: ORDERS.UPDATE(data),
      onSuccess: () => putOrders(data),
      onFailure: e => errorOrders(e.response.data)
    })
  );
};