import axios from "axios";

import Auth from "./auth";
//import { USER } from "./endpoints";
import { API_DOMAIN } from "../constants/api";
import { defined } from "../utils/define";
import { loadFromStorage } from "../utils/local-storage";
const enforcer = Symbol();

class AxiosInstance {
  axios;
  static _instance;

  constructor(enf) {
    if (enf !== enforcer) {
      throw new Error("Error: Singleton, use .instance to access properties");
    }
    this.axios = axios;
  }

  static get instance() {
    if (!AxiosInstance._instance) {
      AxiosInstance._instance = new AxiosInstance(enforcer);
    }
    return AxiosInstance._instance;
  }

  makeAuthHeader = () => ({
    Authorization: `Bearer ${Auth.accessToken}`
  });

  makeHeader = header => ({
    ...header,
    ...this.makeAuthHeader()
  });

  request = ({ url, headers, params, ...config }) => {
    return this.axios({
      ...config,
      params: {
        ...params
      },
      url: `${API_DOMAIN}${url}`,
      headers: this.makeHeader(headers)
    }).then(res => res.data);
  }
}

//export { USER };
export default AxiosInstance.instance;
