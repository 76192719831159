import { BANNERS_ACTIONS } from "../types";
import { BANNERS, PHOTOS } from "../../api/endpoints";
import { apiAction } from "./api";

const setBanners = banners => ({
  type: BANNERS_ACTIONS.SET,
  banners
});

const setMessage = message => ({
  type: BANNERS_ACTIONS.MESSAGE,
  message
});

const setBanner = banner => ({
  type: BANNERS_ACTIONS.ADD,
  banner
});

const putBanner = banner => ({
  type: BANNERS_ACTIONS.UPDATE,
  banner
});

const removeBanner = banner => ({
  type: BANNERS_ACTIONS.REMOVE,
  banner
});

const errorBanners = error => ({
  type: BANNERS_ACTIONS.ERROR,
  error
});

export const getBanners = () => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: BANNERS.GET(),
      onSuccess: data => setBanners(data),
      onFailure: e => e
    })
  );
};

export const addBanner = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: BANNERS.ADD(data),
      onSuccess: data => setBanner(data),
      onFailure: e => errorBanners(e.response.data)
    })
  );
};

export const updateBanner = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: BANNERS.UPDATE(data),
      onSuccess: () => putBanner(data),
      onFailure: e => errorBanners(e.response.data)
    })
  );
};

export const deleteBanners = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: BANNERS.DELETE(data),
      onSuccess: () => removeBanner(data),
      onFailure: e =>errorBanners(e.response.data)
    })
  );
};

export const setPhoto = data => dispatch => {
  return dispatch(
    apiAction({
      requestConfig: PHOTOS.SET_PHOTO(data, "banners"),
      onSuccess: data => setMessage(data),
      onFailure: e => errorBanners(e.response.data)
    })
  );
};
