import React, { Component } from "react";
import { Icon, Tooltip } from "antd";
import message from "components/message";
import { AnyObject } from "../../types";
import { Moment } from "moment";
import { defined } from "../define";

const regexPhone = /^\+7 \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/;
const regexPhone2 = /^([0-9]{11})$/;
const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const required = {
  errorMessage: (label: string) => `Поле не заполнено`,
  validate: (value: any, model: any) =>
    (!!value || value === 0) && !!value.toString().trim().length
};

export const requiredPass = (idName: string) => ({
  errorMessage: (label: string) => `Поле не заполнено`,
  validate: (value: any, model: any) =>
    !defined(model[idName])
      ? (!!value || value === 0) && !!value.toString().trim().length
      : true
});

export const validPhone = {
  errorMessage: (label: string) => `Не верный номер телефона`,
  validate: (value: any, model: any) =>
    regexPhone.test(value) || regexPhone2.test(value) || value === ""
};

export const validEmail = {
  errorMessage: (label: string) => `Не верный e-mail`,
  validate: (value: any, model: any) => regexEmail.test(value) || value === ""
};

export interface FormProps {
  error: any;
  closeSelf?: () => void;
  setPhoto?: Function;
}

export interface FormState {
  loading: boolean;
  model: any;
  validationErrors: any;
  tooltipError: any[];
  formHeight: number;
  animateActive: string;
  animate: {
    [key: string]: string;
  };
  [key: string]: any;
}

export class FormComponent<Props, State> extends Component<
  Props & FormProps,
  FormState
> {
  className = "app-animate-left";

  state: FormState = {
    loading: false,
    model: {},
    validationErrors: [],
    tooltipError: [],
    formHeight: 0,
    animateActive: "",
    animate: {},
  };

  static defaultProps = {
    error: []
  };

  handleSetState = async (name: string, value: string) => {
    //@ts-ignore
    this.setState({ [name]: value });
  };

  handleSetStorage = async (name: string, value: string) => {
    localStorage.setItem(name, value);
  };

  handleOnChangeInput = async (
    name: string,
    value: string | Moment | null | number | any[] | undefined
  ) => {
    await this.setState({
      model: {
        ...this.state.model,
        [name]: value
      },
      tooltipError: []
    });

    this.deleteError(name);
  };

  setHeightForm = () => {
    const formActive = document.getElementsByClassName(
      "app-animate-left active"
    )[0] as HTMLElement;

    this.setState({ formHeight: formActive.offsetHeight });
  };

  validationForm = (validationRules: any[]) => {
    let errors = {};

    validationRules.forEach(rules => {
      //if (typeof this.state.model[rules.field] !== "undefined") {
      const field = this.state.model[rules.field];

      (rules.rules || []).forEach((rule: any) => {
        if (!rule.validate(field, this.state.model)) {
          errors = {
            ...errors,
            [rules.field]: [
              //@ts-ignore
              ...(errors[rules.field] || []),
              rule.errorMessage(rules.field)
            ]
          };
        }
      });
      //}
    });

    this.setState({ validationErrors: errors });
    return Object.entries(errors).length === 0;
  };

  deleteError = (field: string) => {
    if (!!this.state.validationErrors && !!this.state.validationErrors[field]) {
      this.setState({
        validationErrors: {
          ...this.state.validationErrors,
          [field]: []
        }
      });
    }
  };

  validateTooltip = (field: string) => {
    return this.isError(field) ? this.state.validationErrors[field][0] : "";
  };

  submitForm = async (fields: any[] | null, action: Function) => {
    this.setState({ loading: true, tooltipError: [] });

    if (fields === null || this.validationForm(fields)) {
      const results = await action();

      if (!!this.props.error && this.props.error.length > 0) {
        await this.setState({
          validationErrors: this.props.error.reduce(
            (acc: AnyObject, item: AnyObject) => ({
              ...acc,
              [item.field]: [item.message]
            }),
            {}
          )
        });
      } else if (results) {
        if (results !== "without message") {
          message.success("Данные успешно добавлены, информация обновлена");
          if (this.props.closeSelf) this.props.closeSelf();
        }
      } else {
        message.error("Ошибка на сервере. Попробуйте позже");
      }
    }
    this.setState({ loading: false });
  };

  getStandartProps = (field: AnyObject) => ({
    name: field.field,
    value: this.state.model[field.field],
    error: this.validateTooltip(field.field),
    placeholder: field.label,
    onChange: (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => this.handleOnChangeInput(e.target.name, e.target.value)
  });

  isError = (fieldName: string) =>
    !!this.state.validationErrors[fieldName] &&
    this.state.validationErrors[fieldName].length > 0;

  getTooltipError = (fieldName: string) =>
    this.isError(fieldName) && (
      <Tooltip
        className="error-tooltip"
        title={this.state.validationErrors[fieldName][0]}
      >
        <Icon
          className="error"
          type="info-circle"
          style={{ color: "#f5222d" }}
        />
      </Tooltip>
    );

  getErrorClass = (fieldName: string) =>
    this.isError(fieldName) ? "form-error" : "";
}
