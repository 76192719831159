import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import "./style.scss";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/pages";
import { AnyObject } from "../../../types";
import { PagesSelector } from "../../../redux/selectors";
import { Pages } from "../../../models/pages";
import { addPage, updatePage } from "../../../redux/actions/pages";
//@ts-ignore
import CKEditor from 'ckeditor4-react-advanced';
import { Select } from "../../inputs/select";

interface Props extends FormProps {
  model: Pages;
  updatePage: (data: Pages) => void;
  addPage: (data: Pages) => void;
  message: AnyObject;
  pages: Pages[];
}

interface State extends FormState {}

class PagesAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {
      pageParent: 1,
      pageText: ""
    } as Pages,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };



  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idPage) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idPage
        ? await this.props.updatePage(this.state.model)
        : await this.props.addPage(this.state.model)
    );
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-pages">
        <div className="block">
          <label>
            {fields.pageName.title}:
            <Input {...this.getStandartProps(fields.pageName)} />
          </label>

          <label>
            {fields.pageParent.title}:
            <Select
              name={"pageParent"}
              options={this.props.pages}
              labelGetter={(item: Pages) => item.pageName}
              valueGetter={(item: Pages) => item.idPage}
              placeholder={fields.pageParent.title}
              value={this.state.model.pageParent}
              onChange={(val: number) =>
                this.handleOnChangeInput("pageParent", val)
              }
            />
          </label>

          <div className="column">
            <label>
              {fields.pagePath.title}:
              <Input {...this.getStandartProps(fields.pagePath)} />
            </label>

            <label>
              {fields.pageOrder.title}:
              <Input {...this.getStandartProps(fields.pageOrder)} />
            </label>
          </div>

          <div>
            <label>
              {fields.pageText.title}:{this.getTooltipError("pageText")}
            </label>

            <CKEditor
              config={{
                language: "ru",
                allowedContent: true,
                toolbar: [
                  { name: 'document', items: [ 'Source', '-'] },
                  { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                  { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                  '/',
                  { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
                  { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                  { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
                  '/',
                  { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                  { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                  { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                  { name: 'about', items: [ 'About' ] }
                  ]
              }}
              data={this.props.model ? this.props.model.pageText : ''}
              onChange={(evt: any) => {
                this.handleOnChangeInput("pageText", evt.editor.getData());
              }}
            />
          </div>
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(PagesSelector, { addPage, updatePage })(PagesAddEdit);
