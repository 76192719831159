import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import {MODAL_CATALOG_INSETS} from "../../../constants/modal";
import InsetsAddEdit from "../../forms/catalog/insetsAddEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_CATALOG_INSETS);

      return (
        <Modal
          width={300}
          destroyOnClose
          isOpen={isOpenModal(MODAL_CATALOG_INSETS)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idSize
              ? "Редактировать вставку"
              : "Добавить вставку"
          }
          actions={{
            onOk: () => closeModal(MODAL_CATALOG_INSETS),
            onClose: () => closeModal(MODAL_CATALOG_INSETS)
          }}
          render={() => (
            <InsetsAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_CATALOG_INSETS)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
