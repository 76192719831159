import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import { API_DOMAIN } from "../../../constants/api";

import "./style.scss";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/banners";
import { AnyObject } from "../../../types";
import Photo from "../../inputs/photo";
import { Banners } from "../../../models/banners";
import { BannersSelector } from "../../../redux/selectors";

import {
  addBanner,
  setPhoto,
  updateBanner
} from "../../../redux/actions/banners";
import message from "components/message";
import { defined } from "../../../utils/define";

//@ts-ignore
import CKEditor from 'ckeditor4-react-advanced';
import { Pages } from "../../../models/pages";
import {BANNERS_PHOTO, CATALOG_PHOTO, CATALOG_SMALL_PHOTO} from "../../../constants/main";

interface Props extends FormProps {
  model: Banners;
  updateBanner: (data: Banners) => void;
  addBanner: (data: Banners) => void;
  setPhoto: Function;
  message: AnyObject;
}

interface State extends FormState {}

class BannersAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {
      bannerText: ""
    } as Banners,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idBanner) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idBanner
        ? await this.props.updateBanner(this.state.model)
        : await this.props.addBanner(this.state.model)
    );
  };

  handleLoading = (status: boolean) => {
    this.setState({ loading: status });
  };

  handleLoadImage = () => {
    if (!!this.props.message.success && !!this.props.message.filePath) {
      this.handleOnChangeInput("bannerPhoto", `${this.props.message.filePath}`);
    } else {
      if (defined(this.props.error)) {
        message.error(
          defined(this.props.error.message)
            ? this.props.error.message
            : "Ошибка на сервере. Попробуйте позже"
        );
      } else {
        message.error("Ошибка на сервере. Попробуйте позже");
      }
    }
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-banners">
        <div className="block">
          <div className="column">
            <div>
              <label>
                {fields.bannerPhoto.title}:{this.getTooltipError("bannerPhoto")}
              </label>

              <Photo
                setPhoto={this.props.setPhoto}
                filePath={"banners"}
                onChange={() => this.handleLoadImage()}
                loading={(status: boolean) => this.handleLoading(status)}
                imageUrl={`${API_DOMAIN}${BANNERS_PHOTO}${this.state.model.bannerPhoto}`}
              />
            </div>

            <div>
              <label>
                {fields.bannerName.title}:
                <Input {...this.getStandartProps(fields.bannerName)} />
              </label>

              <div className="column">
                <label>
                  {fields.bannerUrl.title}:
                  <Input {...this.getStandartProps(fields.bannerUrl)} />
                </label>

                <label>
                  {fields.bannerOrder.title}:
                  <Input {...this.getStandartProps(fields.bannerOrder)} />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label>
            {fields.bannerText.title}:{this.getTooltipError("bannerText")}
          </label>

          <CKEditor
            config={{
              language: "ru",
              allowedContent: true,
              toolbar: [
                { name: 'document', items: [ 'Source', '-'] },
                { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
                '/',
                { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
                { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
                '/',
                { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
                { name: 'about', items: [ 'About' ] }
              ]
            }}
            data={this.props.model ? this.props.model.bannerText : ""}
            onChange={(evt: any) => {
              this.handleOnChangeInput("bannerText", evt.editor.getData());
            }}
          />
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(BannersSelector, { setPhoto, addBanner, updateBanner })(
  BannersAddEdit
);
