import { required } from "../../reactive";
import React from "react";
import { MODAL_CATALOG_INSETS } from "../../../constants/modal";
import { ModalsConsumer } from "components/modals/context";
import { Button, Modal } from "antd";

export const fields = [
  {
    field: "insetName",
    title: "Вставка",
    rules: [required]
  },
  {
    field: "delete",
    title: "",
    width: 72,
    rules: [],
    render: (text, record) =>
      !record.noButton && (
        <Button
          className="btn-delete"
          shape="circle"
          icon="delete"
          size="large"
          onClick={() => {
            Modal.confirm({
              title: record.materialName,
              content: "Удалить размер",
              okText: "Да",
              okType: "danger",
              cancelText: "Нет",
              width: 276,
              zIndex: 10001,
              onOk() {
                record.deleteInset(record);
              },
              onCancel() {}
            });
          }}
        />
      )
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_CATALOG_INSETS, {
                contextData: record
              });
              openModal(MODAL_CATALOG_INSETS);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
