import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/catalog/categories";
import { AnyObject } from "../../../types";
import { PagesSelector } from "../../../redux/selectors";
import { addCategory, updateCategory } from "../../../redux/actions/catalog";
import Categories from "../../../models/catalog/categories";

interface Props extends FormProps {
  model: Categories;
  updateCategory: (data: Categories) => void;
  addCategory: (data: Categories) => void;
  message: AnyObject;
  categories: Categories[];
}

interface State extends FormState {}

class CategoriesAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,
    validationErrors: [],
    model: {} as Categories,
    loading: false,
    tooltipError: [],
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idCategory) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idCategory
        ? await this.props.updateCategory(this.state.model)
        : await this.props.addCategory(this.state.model)
    );
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-pages">
        <div className="block">
          <label>
            {fields.categoryName.title}:
            <Input {...this.getStandartProps(fields.categoryName)} />
          </label>
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(PagesSelector, { addCategory, updateCategory })(CategoriesAddEdit);
