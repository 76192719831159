import React from "react";
import {Icon, Modal} from "antd";

import { DEFAULT_MODAL_Z_INDEX } from "constants/modal";

export default ({
  key,
  style = {},
  width = 500,
  actions = {
    onOk: e => {},
    onClose: e => {}
  },
  footer = null,
  center = true,
  isOpen = false,
  classNames = [],
  closable = false,
  render = () => null,
  title = ``,
  maskClosable = false,
  zIndex = DEFAULT_MODAL_Z_INDEX,
  destroyOnClose = false
}) => {
  return (
    <Modal
      key={key}
      width={width}
      style={style}
      footer={footer}
      visible={isOpen}
      centered={center}
      closable={closable}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      zIndex={zIndex}
      className={classNames.join(" ")}
      onCancel={actions.onClose}
      title={
        <>
          {title}
          <Icon type="close-circle" onClick={() => actions.onClose()} />
        </>
      }
    >
      {render()}
    </Modal>
  );
};
