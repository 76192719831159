import React from "react";
import { connect } from "react-redux";
import { FormComponent, FormProps, FormState } from "../../../utils/reactive";
import OkButton from "../../buttons/ok";
import { Input } from "../../inputs/input";
import { fields, formFields } from "../../../utils/view/catalog/insets";
import { AnyObject } from "../../../types";
import { ModelFormSelector } from "../../../redux/selectors";
import { updateInset, addInset } from "../../../redux/actions/catalog";
import Insets from "../../../models/catalog/insets";

interface Props extends FormProps {
  model: Insets;
  updateInset: (data: Insets) => void;
  addInset: (data: Insets) => void;
  message: AnyObject;
  insets: Insets[];
}

interface State extends FormState {}

class InsetsAddEdit extends FormComponent<Props, State> {
  state: State = {
    ...this.state,

    validationErrors: [],
    model: {} as Insets,
    loading: false,
    tooltipError: [],
    imageUrl: ""
  };

  componentDidMount() {
    if (!!this.props.model && !!this.props.model.idInset) {
      this.setState({ model: this.props.model });
    }
  }

  handleSubmit = async () => {
    this.submitForm(fields, async () =>
      !!this.props.model && !!this.props.model.idInset
        ? await this.props.updateInset(this.state.model)
        : await this.props.addInset(this.state.model)
    );
  };

  render() {
    const fields = formFields as AnyObject;

    return (
      <div className="app-form add-edit-pages">
        <div className="block">
          <label>
            {fields.insetName.title}:
            <Input {...this.getStandartProps(fields.insetName)} />
          </label>
        </div>

        <OkButton
          onClick={this.handleSubmit}
          loading={this.state.loading}
          tooltipText={this.state.tooltipError}
          type={this.state.tooltipError.length > 0 ? "danger" : null}
        >
          Сохранить
        </OkButton>
      </div>
    );
  }
}

export default connect(ModelFormSelector, {
  addInset,
  updateInset
})(InsetsAddEdit);
