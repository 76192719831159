import { required, validEmail, validPhone } from "../../reactive";
import React from "react";
import { MODAL_CATALOG_CATEGORY } from "../../../constants/modal";
import { ModalsConsumer } from "components/modals/context";
import { Button, Modal } from "antd";
import { NavLink } from "react-router-dom";

export const fields = [
  {
    field: "categoryName",
    title: "Категория",
    rules: [required],
    render: (text, record) => (
      <NavLink
        to={`/catalog-models/${record.idCategory}`}
        key={`catalog-models-${record.idCategory}`}
      >
        {text}
      </NavLink>
    )
  },
  {
    field: "delete",
    title: "",
    width: 72,
    rules: [],
    render: (text, record) =>
      !record.noButton && (
        <Button
          className="btn-delete"
          shape="circle"
          icon="delete"
          size="large"
          onClick={() => {
            Modal.confirm({
              title: record.categoryName,
              content: "Удалить категорию",
              okText: "Да",
              okType: "danger",
              cancelText: "Нет",
              width: 276,
              zIndex: 10001,
              onOk() {
                record.deleteCategory(record);
              },
              onCancel() {}
            });
          }}
        />
      )
  }
];

export const tableColumns = fields
  .filter(item => !item.noShow)
  .map(item => ({ ...item, dataIndex: item.field }));

export const formFields = fields.reduce(
  (acc, item) => ({ ...acc, [item.field]: item }),
  {}
);

export const defaultColumns = [
  {
    title: "",
    key: "operation",
    render: (text, record) => (
      <ModalsConsumer>
        {({ openModal, setContextModal }) => (
          <Button
            shape="circle"
            icon="edit"
            size="large"
            onClick={() => {
              setContextModal(MODAL_CATALOG_CATEGORY, {
                contextData: record
              });
              openModal(MODAL_CATALOG_CATEGORY);
            }}
          />
        )}
      </ModalsConsumer>
    )
  }
];
