import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_ORDERS } from "../../../constants/modal";
import Orders from "../../forms/orders/";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_ORDERS);

      return (
        <Modal
          width={800}
          destroyOnClose
          isOpen={isOpenModal(MODAL_ORDERS)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idOrder
              ? "Редактировать заказ"
              : "Добавить заказ"
          }
          actions={{
            onOk: () => closeModal(MODAL_ORDERS),
            onClose: () => closeModal(MODAL_ORDERS)
          }}
          render={() => (
            <Orders
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_ORDERS)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
