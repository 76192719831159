import React from "react";
import { ModalsConsumer } from "../context";
import Modal from "../modal.js";
import { MODAL_NEWS } from "../../../constants/modal";
import NewsAddEdit from "../../forms/news/addEdit";

export default () => (
  <ModalsConsumer>
    {({ isOpenModal, closeModal, getContextModal }) => {
      const contextModal = getContextModal(MODAL_NEWS);

      return (
        <Modal
          width={650}
          destroyOnClose
          isOpen={isOpenModal(MODAL_NEWS)}
          title={
            !!contextModal.contextData && !!contextModal.contextData.idPage
              ? "Редактировать новость"
              : "Добавить новость"
          }
          actions={{
            onOk: () => closeModal(MODAL_NEWS),
            onClose: () => closeModal(MODAL_NEWS)
          }}
          render={() => (
            <NewsAddEdit
              model={contextModal.contextData}
              closeSelf={() => closeModal(MODAL_NEWS)}
            />
          )}
        />
      );
    }}
  </ModalsConsumer>
);
